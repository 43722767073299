import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import ContactLayout from "../Layout/ContactLayout";
import Contacts from "../Pages/Contacts";
import List from "../Pages/List";
import Segments from "../Pages/Segments";
import Layout from "../Layout/Layout";
import Bulk from "../Pages/Bulk";
import Deliverability from "../Pages/deliverability";
import Monitor from "../Pages/Monitor";
import Single from "../Pages/Single";
import { useEffect, useState } from "react";
import axios from "axios";
import Login from "../Pages/Login";
import Signup from "../Pages/Singup";
import ForgetPass from "../Pages/ForgetPass";
import SettingsLayout from "../Layout/SettingsLayout";
import Profile from "../Pages/Profile";
import Integrations from "../Pages/Integrations";
import Referrals from "../Pages/Referrals";
import Billing from "../Pages/Billing";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0A2647",
      light: "#2C74B3",
    },
  },
});

function App() {
  const [countriesData, setCountriesData] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      axios
        .get("https://restcountries.com/v3.1/all")
        .then(function (response) {
          setCountriesData(response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
    }, 500);
  }, []);

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Single />} />
            <Route path="single" element={<Single />} />
            <Route path="bulk" element={<Bulk />} />
            <Route path="monitor" element={<Monitor />} />
            <Route path="deliverability" element={<Deliverability />} />
            <Route path="contacts" element={<ContactLayout />}>
              <Route index element={<Contacts />} />
              <Route path="lists" element={<List countriesData={countriesData} />} />
              <Route path="segments" element={<Segments />} />
            </Route>
            <Route path="profile" element={<SettingsLayout />}>
              <Route index element={<Profile />} />
              <Route path="integrations" element={<Integrations />} />
              <Route path="referrals" element={<Referrals />} />
              <Route path="billing" element={<Billing />} />
            </Route>
          </Route>
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="forget-password" element={<ForgetPass />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
