import { Box } from "@mui/material";
import React from "react";
import UploadFile from "../Components/UploadFile";

const HomeJson = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "calc(100vh - 70px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <UploadFile />
    </Box>
  );
};

export default HomeJson;
