import React from 'react'
import { Avatar, Button, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'

import InboxIcon from '@mui/icons-material/MarkAsUnread'
import { useTheme } from '@emotion/react'

const Deliverability = () => {
   const theme = useTheme()
   return (
      <Box
         sx={{
            width: '100%',
            height: 'calc(100vh - 70px)',
            backgroundColor: theme.palette.grey[100],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
         }}>
         <Stack
            sx={{
               width: { xs: '90%', md: '60%' },
               height: '70%',
               borderRadius: '10px',
               backgroundColor: '#FFF',
               padding: '20px',
               textAlign: 'center',
            }}
            alignItems='center'
            justifyContent='center'
            spacing={2}>
            <Avatar
               sx={{
                  backgroundColor: theme.palette.info.light,
                  width: '50px',
                  height: '50px',
                  fontSize: '26px',
               }}>
               <InboxIcon fontSize='inherit' />
            </Avatar>
            <Typography variant='h4' sx={{ color: theme.palette.grey[800] }}>
               Improve your Deliverability
            </Typography>
            <Typography
               sx={{ color: theme.palette.grey[500], fontSize: '20px' }}>
               Start monitoring your deliverability before, during and after you
               send.
            </Typography>
            <Button
               disableElevation
               disableRipple
               variant='contained'
               sx={{ backgroundColor: theme.palette.info.light }}>
               Get Started
            </Button>
         </Stack>
      </Box>
   )
}

export default Deliverability
