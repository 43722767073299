import { Box } from "@mui/material";
import React from "react";
import TableData from "../Components/TableData";

const Table = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "calc(100vh - 70px)",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <TableData />
    </Box>
  );
};

export default Table;
