import { useTheme } from '@emotion/react'
import {
   Box,
   Container,
   Icon,
   IconButton,
   Stack,
   Typography,
   useMediaQuery,
} from '@mui/material'
import React, { useState } from 'react'
import ContactDashBoard from '../Components/Contact/ContactDashBoard'

import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { Outlet } from 'react-router-dom'

const ContactLayout = () => {
   const [openDash, setOpenDash] = useState(false)
   const theme = useTheme()

   const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))

   const handleCloseDash = () => {
      setOpenDash(false)
   }

   return (
      <Box sx={{ position: 'relative' }}>
         <ContactDashBoard open={openDash} handleClose={handleCloseDash} />
         <Container
            maxWidth={false}
            sx={{
               width: matchUpMd ? 'calc(100vw - 240px)' : '100%',
               position: 'absolute',
               top: 0,
               right: 0,
            }}>
            <Stack sx={{ paddingTop: '20px' }}>
               {!matchUpMd && (
                  <IconButton
                     sx={{
                        position: 'absolute',
                        left: '5px',
                        top: '5px',
                        pading: '10px',
                        backgroundColor: theme.palette.grey[100],
                     }}
                     onClick={() => setOpenDash(true)}>
                     <KeyboardDoubleArrowRightIcon fontSize='inherit' />
                  </IconButton>
               )}
               <Outlet />
            </Stack>
         </Container>
      </Box>
   )
}

export default ContactLayout
