import { useTheme } from '@emotion/react'
import {
   Avatar,
   Box,
   Button,
   Checkbox,
   Chip,
   Divider,
   FormControlLabel,
   IconButton,
   InputAdornment,
   Link,
   styled,
   Table,
   TableBody,
   TableCell,
   TableHead,
   TableRow,
   TextField,
   Typography,
} from '@mui/material'
import { Stack } from '@mui/system'
import React, { useState } from 'react'
import LoadListPopover from '../Components/Contact/Contact/LoadListPopover'
import FilterColunmsPopover from '../Components/Contact/Contact/FilterColunmsPopover'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined'
import GroupsIcon from '@mui/icons-material/Groups'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto'
import ViewWeekIcon from '@mui/icons-material/ViewWeek'

import data from '../Data/data'

const StyledCell = styled(TableCell)(({ theme }) => ({
   border: `1px ${theme.palette.grey[400]} solid`,
   fontSize: '16px',
   padding: '0px 15px',
   whiteSpace: 'nowrap',
}))

const Contacts = () => {
   const [subscribers, setSubscribers] = useState(data.subscribers)
   const [checked, setChecked] = useState(
      subscribers.map(sub => {
         return { id: sub.id, checked: false }
      })
   )
   const [anchorLoad, setAnchorLoad] = useState(null)
   const [anchorFilter, setAnchorFilter] = useState(null)
   const [columns, setColumns] = useState([
      { id: 0, name: 'Contacts', disable: true, hide: false },
      { id: 1, name: 'Abonne', hide: false },
      { id: 2, name: 'Blocklisté', hide: false },
      { id: 3, name: 'Email', hide: false },
      { id: 4, name: 'Nom', hide: false },
      { id: 5, name: 'Prénom', hide: false },
      { id: 6, name: 'SMS', hide: false },
      { id: 7, name: 'Dernière modification', hide: false },
      { id: 8, name: 'Date ajoutée', hide: false },
   ])

   const handleLoadClick = event => {
      setAnchorLoad(event.currentTarget)
   }

   const handleLoadClose = () => {
      setAnchorLoad(null)
   }

   const handleFilterClick = event => {
      setAnchorFilter(event.currentTarget)
   }

   const handleFilterClose = () => {
      setAnchorFilter(null)
   }

   const theme = useTheme()

   const handleCheckAll = event => {
      setChecked(
         checked.map(check => {
            return { id: check.id, checked: event.target.checked }
         })
      )
   }

   // This handling the check of all the contacts using the id of contact that is in the checkbox
   const handleCheckItem = event => {
      setChecked(
         checked.map(check => {
            if (check.id === +event.target.id) {
               check.checked = event.target.checked
            }
            return check
         })
      )
   }

   const handleCheckColumn = (event, id) => {
      setColumns(
         columns.map(col => {
            if (col.id === id && col.disable !== true) {
               col.hide = event.target.checked
            }
            return col
         })
      )
   }

   return (
      <div>
         <Stack
            direction='row'
            spacing={2}
            justifyContent='space-between'
            sx={{ marginTop: { xs: '30px', md: '0' } }}>
            <Typography variant='h5'>Contacts</Typography>
            <Stack
               spacing={1}
               direction='row'
               alignItems='center'
               justifyContent='space-between'>
               <Button
                  disableElevation
                  disableTouchRipple
                  sx={{
                     fontSize: '14px',
                     textTransform: 'unset',
                     color: theme.palette.primary.light,
                     borderColor: theme.palette.primary.light,
                     backgroundColor: '',
                  }}
                  variant='outlined'>
                  Importer des contacts
               </Button>
               <Button
                  disableElevation
                  disableTouchRipple
                  sx={{
                     fontSize: '14px',
                     textTransform: 'unset',
                     backgroundColor: theme.palette.primary.light,
                     '&:hover': {
                        backgroundColor: theme.palette.primary.light,
                     },
                  }}
                  variant='contained'>
                  Ajouter un contact
               </Button>
            </Stack>
         </Stack>

         <Stack spacing={3} sx={{ marginTop: '30px' }}>
            <Box>
               <Stack
                  direction='row'
                  spacing={2}
                  justifyContent='space-between'>
                  <Stack direction='row' spacing={2}>
                     <Link
                        underline='none'
                        sx={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           color: theme.palette.grey[600],
                           cursor: 'pointer',
                           '&:hover ': {
                              color: theme.palette.primary.light,
                           },
                        }}>
                        Tous les contacts
                     </Link>
                     <Link
                        onClick={handleLoadClick}
                        underline='none'
                        sx={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           color: theme.palette.grey[600],
                           cursor: 'pointer',
                           '&:hover ': {
                              color: theme.palette.primary.light,
                           },
                        }}>
                        <Typography>Charger la liste / Segment </Typography>
                        {Boolean(anchorLoad) ? (
                           <ArrowDropUpIcon />
                        ) : (
                           <ArrowDropDownIcon />
                        )}
                     </Link>
                     <LoadListPopover
                        anchorEl={anchorLoad}
                        handleClose={handleLoadClose}
                     />
                  </Stack>
                  <Button
                     variant='outlined'
                     size='small'
                     sx={{
                        fontSize: '12px',
                        color: theme.palette.primary.light,
                        borderColor: theme.palette.primary.light,
                     }}
                     startIcon={<AddCircleOutlineIcon />}>
                     Ajouter un filtre
                  </Button>
               </Stack>

               <Divider sx={{ marginTop: '5px' }} />
            </Box>
            <Stack
               justifyContent='space-between'
               sx={{ padding: '10px 0' }}
               direction={{ xs: 'column', sm: 'row' }}>
               <Typography
                  component='p'
                  variant='h6'
                  sx={{ textAlign: 'center', fontWeight: '600' }}>
                  {data.subscribers.length} Contacts
               </Typography>
               <Stack
                  spacing={1}
                  direction={{ xs: 'column', md: 'row', flex: 1 }}>
                  <Button
                     size='small'
                     sx={{
                        display: 'flex',
                        color: theme.palette.primary.light,
                        cursor: 'pointer',
                        margin: '0 auto',
                        width: 'fit-content',
                     }}>
                     <ViewWeekOutlinedIcon sx={{ marginRight: '10px' }} />{' '}
                     Modifier les colonnes
                  </Button>
                  <TextField
                     sx={{ width: { xs: '100%', md: 'unset' } }}
                     label='Rechercher par nom, e-mail ou numéro de téléphone'
                     size='small'
                     InputProps={{
                        endAdornment: (
                           <InputAdornment position='end'>
                              <IconButton edge='end'>
                                 <SearchOutlinedIcon />
                              </IconButton>
                           </InputAdornment>
                        ),
                     }}
                  />
               </Stack>
            </Stack>
            <Box>
               <Stack alignItems='flex-end' sx={{ marginBottom: '10px' }}>
                  <Button
                     onClick={handleFilterClick}
                     disableElevation
                     size='small'
                     variant='outlined'
                     startIcon={<ViewWeekIcon />}>
                     Filter Columns
                  </Button>
                  {
                     <FilterColunmsPopover
                        columns={columns}
                        handleCheckColumn={(event, id) =>
                           handleCheckColumn(event, id)
                        }
                        anchorEl={anchorFilter}
                        handleClose={handleFilterClose}
                     />
                  }
               </Stack>
               <Box sx={{ width: '100%', overflowX: 'scroll' }}>
                  <Table>
                     <TableHead>
                        <TableRow
                           sx={{ backgroundColor: theme.palette.grey[300] }}>
                           <StyledCell>
                              <FormControlLabel
                                 label='Contacts'
                                 control={
                                    <Checkbox
                                       onChange={handleCheckAll}
                                       checked={
                                          checked.find(
                                             check => check.checked === false
                                          ) === undefined
                                             ? true
                                             : false
                                       }
                                       indeterminate={
                                          checked.find(
                                             check => check.checked === false
                                          ) !== undefined
                                             ? checked.find(
                                                  check =>
                                                     check.checked === true
                                               ) !== undefined
                                                ? true
                                                : false
                                             : false
                                       }
                                    />
                                 }
                              />
                           </StyledCell>
                           {columns.map(col => {
                              console.log({ col })
                              if (col.name === 'Contacts' || col.hide) {
                                 return
                              }
                              return (
                                 <StyledCell key={col.id}>
                                    {col.name}
                                 </StyledCell>
                              )
                           })}
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {subscribers.map(sub => (
                           <TableRow key={sub.id}>
                              <StyledCell
                                 sx={{ fontSize: '15px', padding: '5px 15px' }}>
                                 <FormControlLabel
                                    control={
                                       <Checkbox
                                          id={`${sub.id}`}
                                          onChange={event =>
                                             handleCheckItem(event)
                                          }
                                          checked={checked[sub.id].checked}
                                       />
                                    }
                                 />
                                 <Link
                                    underline='hover'
                                    sx={{
                                       color: theme.palette.primary.light,
                                       cursor: 'pointer',
                                    }}>
                                    {sub.email
                                       ? sub.email
                                       : `${sub.firstName} ${sub.lastName}`}
                                 </Link>
                              </StyledCell>

                              {!columns?.find(e => e.id === 1)?.hide && (
                                 <StyledCell
                                    sx={{
                                       fontSize: '15px',
                                       padding: '5px 15px',
                                    }}>
                                    <Stack spacing={1} direction='row'>
                                       {sub.email && (
                                          <Chip
                                             avatar={
                                                <Avatar
                                                   sx={{
                                                      backgroundColor:
                                                         'transparent',
                                                   }}>
                                                   <EmailOutlinedIcon />
                                                </Avatar>
                                             }
                                             label='Email'
                                          />
                                       )}
                                       {sub.sms && (
                                          <Chip
                                             avatar={
                                                <Avatar
                                                   sx={{
                                                      backgroundColor:
                                                         'transparent',
                                                   }}>
                                                   <SmsOutlinedIcon />
                                                </Avatar>
                                             }
                                             label='SMS'
                                          />
                                       )}
                                    </Stack>
                                 </StyledCell>
                              )}
                              {!columns?.find(e => e.id === 2)?.hide && (
                                 <StyledCell
                                    sx={{
                                       fontSize: '15px',
                                       padding: '5px 15px',
                                    }}>
                                    {sub.block ? 'true' : 'false'}
                                 </StyledCell>
                              )}
                              {!columns?.find(e => e.id === 3)?.hide && (
                                 <StyledCell
                                    sx={{
                                       fontSize: '15px',
                                       padding: '5px 15px',
                                    }}>
                                    {sub.email}
                                 </StyledCell>
                              )}
                              {!columns?.find(e => e.id === 4)?.hide && (
                                 <StyledCell
                                    sx={{
                                       fontSize: '15px',
                                       padding: '5px 15px',
                                    }}>
                                    {sub.lastName}
                                 </StyledCell>
                              )}
                              {!columns?.find(e => e.id === 5)?.hide && (
                                 <StyledCell
                                    sx={{
                                       fontSize: '15px',
                                       padding: '5px 15px',
                                    }}>
                                    {sub.firstName}
                                 </StyledCell>
                              )}
                              {!columns?.find(e => e.id === 6)?.hide && (
                                 <StyledCell
                                    sx={{
                                       fontSize: '15px',
                                       padding: '5px 15px',
                                    }}>
                                    {sub.sms}
                                 </StyledCell>
                              )}
                              {!columns?.find(e => e.id === 7)?.hide && (
                                 <StyledCell
                                    sx={{
                                       fontSize: '15px',
                                       padding: '5px 15px',
                                    }}>
                                    {sub.Overview.history.add.at}
                                 </StyledCell>
                              )}
                              {!columns?.find(e => e.id === 8)?.hide && (
                                 <StyledCell
                                    sx={{
                                       fontSize: '15px',
                                       padding: '5px 15px',
                                    }}>
                                    {sub.Overview.history.addToList.at}
                                 </StyledCell>
                              )}
                           </TableRow>
                        ))}
                     </TableBody>
                  </Table>
               </Box>
            </Box>
         </Stack>
      </div>
   )
}

export default Contacts
