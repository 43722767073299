import { useTheme } from '@emotion/react'
import {
   Checkbox,
   Divider,
   FormControlLabel,
   FormGroup,
   Popover,
   Stack,
   Typography,
} from '@mui/material'
import React from 'react'

const FilterColunmsPopover = ({
   anchorEl,
   handleClose,
   columns,
   handleCheckColumn,
}) => {
   const theme = useTheme()
   return (
      <Popover
         open={Boolean(anchorEl)}
         anchorEl={anchorEl}
         onClose={handleClose}
         anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
         }}>
         <Stack
            spacing={1}
            sx={{
               padding: '15px',
               width: { xs: '180px', sm: '250px' },
               height: '260px',
               overflowY: 'scroll',
               margin: '0 auto',
            }}
            direction='column'>
            <Typography
               sx={{ color: theme.palette.primary.main, fontSize: 17 }}
               variant='body1'>
               Check to hide colmuns :
            </Typography>
            <Divider sx={{ marginTop: '5px' }} />
            <FormGroup sx={{ width: '100%' }}>
               {columns.map(col => (
                  <FormControlLabel
                     size='small'
                     sx={{
                        width: '100%',
                        '& .MuiCheckbox-root': {
                           padding: '5px',
                           marginRight: '10px',
                        },
                     }}
                     key={col.id}
                     id={col.id}
                     control={
                        <Checkbox
                           onChange={e => handleCheckColumn(e, col.id)}
                           checked={col.hide}
                           disabled={col?.disable}
                        />
                     }
                     label={col.name}
                  />
               ))}
            </FormGroup>
         </Stack>
      </Popover>
   )
}

export default FilterColunmsPopover
