import { useTheme } from '@emotion/react'
import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'

const DragFile = ({ nextHandler, file, getRootProps, getInputProps }) => {
   const theme = useTheme()
   return (
      <Stack spacing={2} alignItems='flex-end'>
         <Box
            sx={{
               width: '100%',
               height: '250px',
               backgroundColor: theme.palette.grey[200],
               borderRadius: '5px',
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               cursor: 'pointer',
            }}
            {...getRootProps()}>
            <input {...getInputProps()} />
            {file == null && (
               <Typography sx={{ padding: '15px', textAlign: 'center' }}>
                  Drag 'n' drop only excel file here, or click to select files
               </Typography>
            )}
            {file != null && <p>{file[0].path}</p>}
         </Box>
         <Button
            disableElevation
            variant='contained'
            sx={{ textTransform: 'uppercase', width: 'fit-content' }}
            onClick={nextHandler}>
            Import
         </Button>
      </Stack>
   )
}

export default DragFile
