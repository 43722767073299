const data = {
   fullName: 'The Full Name',
   credit: 250,
   isVerify: true,
   verifiedEmail: true,
   lists: [
      {
         id: 0,
         name: 'My list 1',
         numContacts: '3',
         createAt: '2/16/2023',
         folder: 1,
      },
      {
         id: 1,
         name: 'My list 2',
         numContacts: '2',
         createAt: '2/18/2023',
         folder: 1,
      },
      {
         id: 2,
         name: 'My list 3',
         numContacts: '10',
         createAt: '2/26/2023',
         folder: 1,
      },
      {
         id: 3,
         name: 'Mylist 1',
         numContacts: '3',
         createAt: '2/16/2023',
         folder: 2,
      },
      {
         id: 4,
         name: 'Mylist 2',
         numContacts: '2',
         createAt: '2/20/2023',
         folder: 2,
      },
      {
         id: 5,
         name: 'Mylist 3',
         numContacts: '10',
         createAt: '2/21/2023',
         folder: 2,
      },
   ],
   folders: [
      { id: 0, name: 'folder One' },
      { id: 1, name: 'folder Two' },
      { id: 2, name: 'folder Three' },
   ],
   subscribers: [
      {
         id: 0,
         firstName: 'hamza',
         lastName: 'aaa',
         sms: '+212611223344',
         email: '',
         list: 'mylist 1',
         block: false,
         Overview: {
            emails: {
               sent: 0,
               delivered: 0,
               uniqueOpening: 0,
               uniqueClicks: 0,
            },
            history: {
               add: {
                  by: 'you',
                  at: 'Feb 23, 2023 12:22 PM',
               },
               addToList: {
                  list: 'mylist 1',
                  at: 'Feb 23, 2023 12:22 PM',
               },
            },
         },
      },
      {
         id: 1,
         firstName: 'mustapha',
         lastName: 'bbb',
         sms: '+212788552211',
         email: 'mustapha@email.com',
         list: 'mylist 2',
         Overview: {
            emails: {
               sent: 0,
               delivered: 0,
               uniqueOpening: 0,
               uniqueClicks: 0,
            },
            history: {
               add: {
                  by: 'you',
                  at: 'Feb 26, 2023 10:20 PM',
               },
               addToList: {
                  list: 'mylist 1',
                  at: 'Feb 26, 2023 10:20 PM',
               },
            },
         },
      },
   ],
   segments: [],
}

export default data
