import React from 'react'
import PhoneInputComponent from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import style from './style.module.scss'

function PhoneInput({ country, value, onChange, styleContainer }) {
   return (
      <div className={style.phone_input_component}>
         <PhoneInputComponent
            containerClass={style.containerClass}
            buttonClass={style.buttonClass}
            inputClass={style.inputClass}
            enableSearch
            country={country}
            value={value}
            onChange={onChange}
            containerStyle={{ ...styleContainer }}
         />
      </div>
   )
}

PhoneInput.propTypes = {}
PhoneInput.defaultProps = {}

export default PhoneInput
