import React from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";

const Home = () => {
  const navigate = useNavigate();
  const projects = [
    {
      id: 0,
      name: "Json Translator",
      description:
        "This website simplifies JSON file translation. Upload your file, select the target language, write the translation, and download the new version. No technical skills required.",
      link: "/json-translator",
    },
    {
      id: 1,
      name: "Email Checker",
      description:
        "Provides an email verification service. Simply upload your file and start checking if each email address exists or not. Fast and reliable, it helps you keep your mailing list clean and effective.",
      link: "/email-checker",
    },
  ];
  return (
    <div className="container">
      <div className="projects-container">
        {projects.map((project) => (
          <div className="project" key={project.id}>
            <h2>{project.name}</h2>
            <p>{project.description}</p>
            <div>
              <button
                onClick={() => {
                  navigate(`${project.link}`);
                }}
              >
                Start
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
