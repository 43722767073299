import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../Home/Home";
import JsonTranslator from "../json-translator/index";
import HomeJson from "../json-translator/Pages/HomeJson";
import Table from "../json-translator/Pages/Table";
import EmailChecker from "../email_checker/index";
import Single from "../email_checker/Pages/Single";
import Bulk from "../email_checker/Pages/Bulk";
import { Contacts, Login, Monitor } from "@mui/icons-material";
import Deliverability from "../email_checker/Pages/deliverability";
import ContactLayout from "../email_checker/Layout/ContactLayout";
import { List } from "@mui/material";
import Segments from "../email_checker/Pages/Segments";
import SettingsLayout from "../email_checker/Layout/SettingsLayout";
import Profile from "../email_checker/Pages/Profile";
import Integrations from "../email_checker/Pages/Integrations";
import Referrals from "../email_checker/Pages/Referrals";
import Billing from "../email_checker/Pages/Billing";
import Signup from "../email_checker/Pages/Singup";
import ForgetPass from "../email_checker/Pages/ForgetPass";
import { useEffect, useState } from "react";
import axios from "axios";

function App() {
  const [countriesData, setCountriesData] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      axios
        .get("https://restcountries.com/v3.1/all")
        .then(function (response) {
          setCountriesData(response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
    }, 500);
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/json-translator" element={<JsonTranslator />}>
          <Route index element={<HomeJson />} />
          <Route path="show-data" element={<Table />} />
        </Route>
        <Route path="/email-checker" element={<EmailChecker />}>
          <Route index element={<Single />} />
          <Route path="single" element={<Single />} />
          <Route path="bulk" element={<Bulk />} />
          <Route path="monitor" element={<Monitor />} />
          <Route path="deliverability" element={<Deliverability />} />
          <Route path="contacts" element={<ContactLayout />}>
            <Route index element={<Contacts />} />
            <Route path="lists" element={<List countriesData={countriesData} />} />
            <Route path="segments" element={<Segments />} />
          </Route>
          <Route path="profile" element={<SettingsLayout />}>
            <Route index element={<Profile />} />
            <Route path="integrations" element={<Integrations />} />
            <Route path="referrals" element={<Referrals />} />
            <Route path="billing" element={<Billing />} />
          </Route>
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="forget-password" element={<ForgetPass />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
