import React, { useState } from 'react'
import {
   AppBar,
   Box,
   Button,
   Paper,
   Stack,
   TextField,
   Toolbar,
   Typography,
   useMediaQuery,
} from '@mui/material'
import Back from '../Assets/delivering-food.svg'
import { useTheme } from '@mui/private-theming'
import { useNavigate } from 'react-router'

import logo from '../Assets/logo.png'

const ForgetPass = () => {
   const theme = useTheme()
   const navigate = useNavigate()
   const [email, setEmail] = useState('')

   const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
   return (
      <div>
         <Box sx={{ backgroundColor: '#F3E8FF' }}>
            <AppBar
               position='sticky'
               elevation={0}
               sx={{ backgroundColor: '#FFF' }}>
               <Toolbar
                  sx={{
                     display: 'flex',
                     justifyContent: 'space-between',
                     alignItems: 'center',
                  }}>
                  <Box
                     component='img'
                     src={logo}
                     alt='logo'
                     sx={{
                        width: { xs: '140px', md: '200px' },
                        objectFit: 'cover',
                     }}
                  />
                  <Button
                     disableElevation
                     variant='contained'
                     sx={{
                        backgroundColor: '#2F0A49',
                        textTransform: 'capitalize',
                     }}
                     onClick={() => navigate('/login')}>
                     Log in
                  </Button>
               </Toolbar>
            </AppBar>
            <Stack
               sx={{ width: '100%', height: 'calc(100vh - 70px)' }}
               direction={{ xs: 'column', md: 'row' }}
               height
               justifyContent='space-evenly'
               alignItems='center'>
               {matchUpMd && (
                  <Box
                     sx={{
                        width: { xs: '90%', sm: '70%', md: '400px' },
                        display: 'flex',
                        justifyContent: 'center',
                     }}>
                     <Box
                        component='img'
                        src={Back}
                        alt='image'
                        sx={{ width: '100%' }}
                     />
                  </Box>
               )}
               <Stack
                  spacing={3}
                  sx={{
                     width: { xs: '90%', sm: '70%', md: '450px' },
                     padding: '30px 15px',
                  }}
                  alignItems='center'
                  component={Paper}
                  elevation={4}>
                  <Typography
                     variant='h5'
                     sx={{ marginBottom: '25px', fontWeight: '400' }}>
                     Reset Password
                  </Typography>
                  <form style={{ width: '100%' }}>
                     <TextField
                        sx={{ width: '100%', marginBottom: '25px' }}
                        size='small'
                        id='email-input'
                        label='Email'
                        value={email}
                        type='email'
                        onChange={event => {
                           setEmail(event.target.value)
                        }}
                     />
                     <Box sx={{ width: '100%' }}>
                        <Button
                           type='submit'
                           disableElevation
                           sx={{
                              width: '100%',
                              backgroundColor: '#2F0A49',
                              '&:hover': { backgroundColor: '#2F0A49' },
                           }}
                           variant='contained'>
                           Forget Password
                        </Button>
                     </Box>
                  </form>
               </Stack>
            </Stack>
         </Box>
      </div>
   )
}

export default ForgetPass
