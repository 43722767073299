import React from "react";
import { useTheme } from "@emotion/react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  useMediaQuery,
} from "@mui/material";

import { Link as routerLink } from "react-router-dom";

import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ListIcon from "@mui/icons-material/List";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import FlashOnIcon from "@mui/icons-material/FlashOn";

const ContactDashBoard = ({ open, handleClose }) => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          height: "calc(100vh - 70px)",
          position: "absolute",
          backgroundColor: theme.palette.grey[200],
          border: "none",
          top: 0,
          left: 0,
        },
        "& .MuiDrawer-paper": {
          width: "240px",
        },
      }}
      anchor="left"
      variant={matchDownMd ? "persistent" : "permanent"}
      onClose={handleClose}
      open={matchDownMd ? open : true}
    >
      <Stack spacing={1}>
        <Stack alignItems="flex-end">
          {matchDownMd && (
            <IconButton
              sx={{
                margin: "5px",
                width: "fit-content",
                pading: "10px",
                backgroundColor: theme.palette.grey[100],
              }}
              onClick={handleClose}
            >
              <KeyboardDoubleArrowLeftIcon fontSize="meduim" />
            </IconButton>
          )}
        </Stack>
        <Stack>
          <List disablePadding>
            <ListItem disablePadding>
              <ListItemButton
                component={routerLink}
                to="/email-checker/contacts"
                alignItems="center"
                disableGutters
                sx={{
                  padding: "8px 15px",
                  borderRadius: "5px",
                }}
              >
                <RecentActorsIcon
                  sx={{
                    width: "fit-content",
                    minWidth: "unset",
                    marginRight: "10px",
                    color: theme.palette.primary.dark,
                    fontSize: "30px",
                  }}
                />
                <ListItemText
                  primary="Contacts"
                  primaryTypographyProps={{
                    color: theme.palette.grey[900],
                    fontSize: "16px",
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={routerLink}
                to="lists"
                alignItems="center"
                disableGutters
                sx={{
                  padding: "8px 15px",
                  borderRadius: "5px",
                }}
              >
                <ListIcon
                  sx={{
                    width: "fit-content",
                    minWidth: "unset",
                    marginRight: "10px",
                    color: theme.palette.primary.dark,
                    fontSize: "30px",
                  }}
                />
                <ListItemText
                  primary="Lists"
                  primaryTypographyProps={{
                    color: theme.palette.grey[900],
                    fontSize: "16px",
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={routerLink}
                to="segments"
                alignItems="center"
                disableGutters
                sx={{
                  padding: "8px 15px",
                  borderRadius: "5px",
                }}
              >
                <FlashOnIcon
                  sx={{
                    width: "fit-content",
                    minWidth: "unset",
                    marginRight: "10px",
                    color: theme.palette.primary.dark,
                    fontSize: "30px",
                  }}
                />
                <ListItemText
                  primary="Segments"
                  primaryTypographyProps={{
                    color: theme.palette.grey[900],
                    fontSize: "16px",
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default ContactDashBoard;
