import { Stack, Typography } from '@mui/material'
import React from 'react'

const Billing = () => {
   return (
      <Stack sx={{ marginTop: { xs: '30px', md: '0' } }}>
         <Typography variant='h4'>Billing</Typography>
      </Stack>
   )
}

export default Billing
