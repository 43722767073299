import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
   Box,
   Stack,
   IconButton,
   Modal,
   Typography,
   Button,
   Divider,
   Autocomplete,
   TextField,
   TableContainer,
   Table,
   TableHead,
   TableRow,
   TableCell,
   TableBody,
   InputLabel,
   FormControl,
   Select,
   MenuItem,
   LinearProgress,
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { useDropzone } from 'react-dropzone'
import { useTheme } from '@emotion/react'
import DragFile from './Elements/DragFile'
import WriteFile from './Elements/WriteFile'
import readXlsxFile from 'read-excel-file'
import axios from 'axios'
import { utils, writeFile } from 'xlsx'
import ModalAddToContact from './Elements/ModalAddToContact'

const SourceStep = ({
   handleClose,
   handleNextStep,
   file,
   handleFileChange,
   handleRowsChange,
   handleEmailsChange,
   emailsArr,
   importMethod,
   handleChangeMethod,
}) => {
   const theme = useTheme()
   const [emails, setEmails] = useState('')

   const onDrop = useCallback(file => {
      handleFileChange(file)
      readXlsxFile(file[0]).then(rows => {
         handleRowsChange(rows)
         console.log(rows)
      })
   }, [])

   const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: {
         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
            '.xlsx',
         ],
         'application/vnd.ms-excel': ['.xls'],
      },
   })

   const handleNextOverride = () => {
      let data = emails.length === 0 ? [] : emails.split(',')
      data = data.map(item => {
         if (item[0] === ' ') {
            return item.substring(1)
         }
         return item
      })
      handleEmailsChange(data)
      handleNextStep()
   }

   return (
      <Box
         sx={{
            width: { xs: '90%', sm: '80%', md: '75%', lg: '55%' },
            padding: '20px',
            backgroundColor: '#fff',
            borderRadius: '15px',
         }}>
         <Stack
            direction='row'
            spacing={2}
            justifyContent='space-between'
            alignItems='center'
            sx={{ height: 'fit-content' }}>
            <Typography variant='h5' sx={{ color: theme.palette.grey[700] }}>
               Select a source
            </Typography>
            <IconButton aria-label='delete' onClick={handleClose}>
               <CloseIcon />
            </IconButton>
         </Stack>
         <Divider sx={{ margin: '15px 0' }} />
         <Stack
            spacing={2}
            justifyContent='center'
            alignItems='center'
            sx={{ minHeight: '350px' }}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
               <Button
                  size='small'
                  variant='contained'
                  disableElevation
                  disableTouchRipple
                  onClick={() => handleChangeMethod('drag')}
                  sx={{
                     backgroundColor: '#95BDFF',
                     fontSize: '12px',
                     color: theme.palette.grey[900],
                     '&:hover': {
                        backgroundColor: '#B4E4FF',
                     },
                  }}
                  startIcon={<FolderCopyOutlinedIcon />}>
                  My Computer
               </Button>
               <Button
                  size='small'
                  variant='contained'
                  onClick={() => handleChangeMethod('write')}
                  disableElevation
                  disableTouchRipple
                  sx={{
                     backgroundColor: '#95BDFF',
                     fontSize: '12px',
                     color: theme.palette.grey[900],
                     '&:hover': {
                        backgroundColor: '#B4E4FF',
                     },
                  }}
                  startIcon={<ContentCopyOutlinedIcon />}>
                  Copy & paste
               </Button>
            </Stack>

            <Stack sx={{ width: '100%' }}>
               {importMethod === 'drag' ? (
                  <DragFile
                     file={file}
                     getRootProps={getRootProps}
                     getInputProps={getInputProps}
                     nextHandler={handleNextStep}
                  />
               ) : (
                  <WriteFile
                     emails={emails}
                     onChange={e => setEmails(e.target.value)}
                     nextHandler={handleNextOverride}
                  />
               )}
            </Stack>
         </Stack>
      </Box>
   )
}

const ColumnStep = ({
   handleClose,
   handleNextStep,
   handleBackStep,
   rowsHeader,
   handleSelectedRowChange,
   selectedRow,
}) => {
   const theme = useTheme()

   return (
      <Box
         sx={{
            width: { xs: '90%', sm: '80%', md: '75%', lg: '55%' },
            padding: '20px',
            backgroundColor: '#fff',
            borderRadius: '15px',
         }}>
         <Stack
            direction='row'
            spacing={2}
            justifyContent='space-between'
            alignItems='center'>
            <Typography variant='h5' sx={{ color: theme.palette.grey[700] }}>
               Select the email column
            </Typography>
            <IconButton aria-label='delete' onClick={handleClose}>
               <CloseIcon />
            </IconButton>
         </Stack>
         <Divider sx={{ margin: '15px 0' }} />
         <Stack
            spacing={2}
            justifyContent='space-between'
            alignItems='flex-end'
            sx={{ width: '100%', height: '350px' }}>
            <Stack
               sx={{ width: '100%', height: '100%' }}
               justifyContent='center'
               alignItems='center'>
               <FormControl sx={{ width: { xs: '100%', md: '60%' } }}>
                  <InputLabel id='demo-simple-select-label'>Column</InputLabel>
                  <Select
                     fullWidth
                     labelId='demo-simple-select-label'
                     id='demo-simple-select'
                     sx={{ width: '100%' }}
                     label='Column'
                     defaultValue={''}
                     value={selectedRow}
                     onChange={e => handleSelectedRowChange(e.target.value)}>
                     {rowsHeader.map((row, index) => (
                        <MenuItem key={index} value={index}>
                           {row}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
            </Stack>

            <Stack
               sx={{ width: '100%' }}
               direction='row'
               justifyContent='space-between'>
               <Button
                  disableElevation
                  variant='outlined'
                  sx={{ textTransform: 'uppercase', width: 'fit-content' }}
                  onClick={handleBackStep}>
                  Back
               </Button>
               <Button
                  disableElevation
                  variant='contained'
                  sx={{ textTransform: 'uppercase', width: 'fit-content' }}
                  onClick={handleNextStep}>
                  Next
               </Button>
            </Stack>
         </Stack>
      </Box>
   )
}

const ConfirmStep = ({
   handleClose,
   handleBackStep,
   handleStart,
   selectedRow,
   rows,
   emails,
}) => {
   const theme = useTheme()

   return (
      <Box
         sx={{
            width: { xs: '90%', sm: '80%', md: '75%', lg: '55%' },
            padding: '20px',
            backgroundColor: '#fff',
            borderRadius: '15px',
         }}>
         <Stack
            direction='row'
            spacing={2}
            justifyContent='space-between'
            alignItems='center'>
            <Typography variant='h5' sx={{ color: theme.palette.grey[700] }}>
               Verify the column
            </Typography>
            <IconButton aria-label='delete' onClick={handleClose}>
               <CloseIcon />
            </IconButton>
         </Stack>
         <Divider sx={{ margin: '15px 0' }} />
         <Stack
            spacing={2}
            justifyContent='space-between'
            alignItems='flex-end'
            sx={{ width: '100%', height: '350px' }}>
            <Stack
               spacing={2}
               sx={{ width: '100%', height: '100%' }}
               justifyContent='center'
               alignItems='center'>
               <Typography>This is the email that will be filtered</Typography>
               <TableContainer>
                  <Table padding='none'>
                     <TableHead>
                        <TableRow sx={{ backgroundColor: '#eee' }}>
                           <TableCell
                              sx={{ padding: '5px', fontSize: '17px' }}
                              align='center'>
                              {rows.length === 0
                                 ? 'Emails'
                                 : rows[0][selectedRow]}
                           </TableCell>
                        </TableRow>
                     </TableHead>
                     {rows.length === 0 ? (
                        <TableBody>
                           {emails?.length < 6 ? (
                              emails.map((email, index) => (
                                 <TableRow key={index}>
                                    <TableCell
                                       sx={{ padding: '5px' }}
                                       align='center'>
                                       {email}
                                    </TableCell>
                                 </TableRow>
                              ))
                           ) : (
                              <>
                                 {emails.slice(0, 3).map((email, index) => (
                                    <TableRow key={index}>
                                       <TableCell
                                          sx={{ padding: '5px' }}
                                          align='center'>
                                          {email}
                                       </TableCell>
                                    </TableRow>
                                 ))}
                                 <TableRow
                                    sx={{
                                       '&:last-child td, &:last-child th': {
                                          border: 0,
                                       },
                                    }}>
                                    <TableCell
                                       sx={{ padding: '5px' }}
                                       align='center'>
                                       <MoreVertIcon />
                                    </TableCell>
                                 </TableRow>
                                 {emails.slice(0, 3).map((email, index) => (
                                    <TableRow key={index}>
                                       <TableCell
                                          sx={{ padding: '5px' }}
                                          align='center'>
                                          {email}
                                       </TableCell>
                                    </TableRow>
                                 ))}
                              </>
                           )}
                        </TableBody>
                     ) : (
                        <TableBody>
                           {rows?.length < 6 ? (
                              rows.slice(1, rows.length).map((row, index) => (
                                 <TableRow
                                    key={index}
                                    sx={{
                                       '&:last-child td, &:last-child th': {
                                          border: 0,
                                       },
                                    }}>
                                    <TableCell
                                       sx={{ padding: '5px' }}
                                       align='center'>
                                       {row[selectedRow]}
                                    </TableCell>
                                 </TableRow>
                              ))
                           ) : (
                              <>
                                 {rows.slice(1, 4).map((row, index) => (
                                    <TableRow
                                       key={index}
                                       sx={{
                                          '&:last-child td, &:last-child th': {
                                             border: 0,
                                          },
                                       }}>
                                       <TableCell
                                          sx={{ padding: '5px' }}
                                          align='center'>
                                          {row[selectedRow]}
                                       </TableCell>
                                    </TableRow>
                                 ))}
                                 <TableRow
                                    sx={{
                                       '&:last-child td, &:last-child th': {
                                          border: 0,
                                       },
                                    }}>
                                    <TableCell
                                       sx={{ padding: '5px' }}
                                       align='center'>
                                       <MoreVertIcon />
                                    </TableCell>
                                 </TableRow>
                                 {rows
                                    .slice(Math.max(rows.length - 3, 0))
                                    .map((row, index) => (
                                       <TableRow
                                          key={index}
                                          sx={{
                                             '&:last-child td, &:last-child th':
                                                { border: 0 },
                                          }}>
                                          <TableCell
                                             sx={{ padding: '5px' }}
                                             align='center'>
                                             {row[selectedRow]}
                                          </TableCell>
                                       </TableRow>
                                    ))}
                              </>
                           )}
                        </TableBody>
                     )}
                  </Table>
               </TableContainer>
            </Stack>

            <Stack
               sx={{ width: '100%' }}
               direction='row'
               justifyContent='space-between'>
               <Button
                  disableElevation
                  variant='outlined'
                  sx={{ textTransform: 'uppercase', width: 'fit-content' }}
                  onClick={handleBackStep}>
                  Back
               </Button>
               <Button
                  disableElevation
                  variant='contained'
                  sx={{ textTransform: 'uppercase', width: 'fit-content' }}
                  onClick={handleStart}>
                  Start
               </Button>
            </Stack>
         </Stack>
      </Box>
   )
}

const VerificationStep = ({
   handleCancelStep,
   handleClose,
   progress,
   handleChangeProgress,
}) => {
   const theme = useTheme()

   return (
      <Box
         sx={{
            width: { xs: '90%', sm: '80%', md: '75%', lg: '55%' },
            padding: '20px',
            backgroundColor: '#fff',
            borderRadius: '15px',
         }}>
         <Stack
            direction='row'
            spacing={2}
            justifyContent='space-between'
            alignItems='center'>
            <Typography variant='h5' sx={{ color: theme.palette.grey[700] }}>
               Verification
            </Typography>
            <IconButton aria-label='delete' onClick={handleClose}>
               <CloseIcon />
            </IconButton>
         </Stack>
         <Divider sx={{ margin: '15px 0' }} />
         <Stack
            spacing={2}
            justifyContent='space-between'
            alignItems='flex-end'
            sx={{ width: '100%', height: '350px' }}>
            <Stack
               sx={{ width: '100%', height: '100%' }}
               justifyContent='center'
               alignItems='center'>
               <Typography sx={{ mb: 2, fontSize: '18px' }}>
                  The emails list are being filtered, please wait...
               </Typography>
               <Box
                  sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Box sx={{ width: '100%', mr: 1 }}>
                     <LinearProgress
                        variant='determinate'
                        value={progress}
                        sx={{
                           backgroundColor: '#eee',
                           height: '10px',
                           '& .MuiLinearProgress-barColorPrimary': {
                              backgroundColor: '#19A7CE',
                           },
                        }}
                     />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                     <Typography variant='body2' color='text.secondary'>
                        {Number(progress).toFixed(2)}%
                     </Typography>
                  </Box>
               </Box>
            </Stack>

            <Stack
               sx={{ width: '100%' }}
               direction='row'
               justifyContent='space-between'>
               <Button
                  disableElevation
                  variant='outlined'
                  sx={{ textTransform: 'uppercase', width: 'fit-content' }}
                  onClick={handleCancelStep}>
                  Cancel
               </Button>
            </Stack>
         </Stack>
      </Box>
   )
}

const RapportStep = ({
   handleClose,
   result,
   downloadFileHandler,
   importMethod,
}) => {
   const theme = useTheme()
   const [validEmailsList, setValidEmailsList] = useState(
      importMethod === 'drag' ? [result[0]] : []
   )
   const [validNotExisEmailsList, setValidNotExisEmailsList] = useState(
      importMethod === 'drag' ? [result[0]] : []
   )
   const [notExistEmailsList, setNotExistEmailsList] = useState(
      importMethod === 'drag' ? [result[0]] : []
   )
   const [showAddModal, setShowAddModal] = useState(false)
   const [modalRows, setModalRows] = useState([])
   const effectRan = useRef(false)

   const handleOpenAddModal = data => {
      setShowAddModal(true)
      setModalRows(data)
   }

   const handleCloseAddModal = () => {
      setShowAddModal(false)
   }

   useEffect(() => {
      const otherErrors = [
         'MX record not found',
         'Must contain a "." after the "@".',
         'Email does not contain "@".',
      ]
      if (effectRan.current === false) {
         result.map(email => {
            if (email[1] === 'Exist') {
               setValidEmailsList(prevState => [...prevState, email])
            } else if (email[1] === 'Mailbox not found.') {
               setValidNotExisEmailsList(prevState => [...prevState, email])
            } else if (otherErrors.includes(email[1])) {
               setNotExistEmailsList(prevState => [...prevState, email])
            }
         })
         effectRan.current = true
      }
      console.log('valid', validEmailsList)
   }, [])

   return (
      <Box
         sx={{
            width: { xs: '90%', sm: '80%', md: '75%', lg: '55%' },
            padding: '20px',
            backgroundColor: '#fff',
            borderRadius: '15px',
         }}>
         <Stack
            direction='row'
            spacing={2}
            justifyContent='space-between'
            alignItems='center'>
            <Typography variant='h5' sx={{ color: theme.palette.grey[700] }}>
               Rapport
            </Typography>
            <IconButton aria-label='delete' onClick={handleClose}>
               <CloseIcon />
            </IconButton>
         </Stack>
         <Divider sx={{ margin: '15px 0' }} />
         <Stack
            spacing={2}
            justifyContent='space-between'
            alignItems='flex-end'
            sx={{ width: '100%', height: '380px', overflowY: 'scroll' }}>
            <Stack
               sx={{ width: '100%', height: '100%' }}
               justifyContent='space-between'
               alignItems='center'>
               <Typography sx={{ mb: 4, fontSize: '18px' }}>
                  The emails list are verified successfully.
               </Typography>
               <Stack
                  direction='row'
                  justifyContent='space-between'
                  sx={{ width: '100%' }}
                  flexWrap='wrap'>
                  <Box
                     sx={{
                        width: { xs: '90%', sm: '47%', md: '31%' },
                        margin: { xs: '15px auto', md: '0' },
                     }}>
                     <Typography
                        variant='body1'
                        sx={{
                           textAlign: 'center',
                           fontSize: '18px',
                           marginBottom: '15px',
                        }}>
                        Exist Emails
                     </Typography>
                     <Typography
                        variant='h2'
                        sx={{
                           textAlign: 'center',
                           marginBottom: '25px',
                           fontWeight: '600',
                           color: theme.palette.primary.main,
                        }}>
                        {validEmailsList.length -
                           (importMethod === 'drag' ? 1 : 0)}
                     </Typography>
                     <Stack spacing={1} direction='column'>
                        <Button
                           disabled={validEmailsList.length === 1}
                           size='small'
                           disableElevation
                           variant='contained'
                           onClick={() => handleOpenAddModal(validEmailsList)}>
                           Add to Contact
                        </Button>
                        <Button
                           disabled={validEmailsList.length === 1}
                           size='small'
                           disableElevation
                           variant='outlined'
                           onClick={() => {
                              downloadFileHandler(validEmailsList)
                           }}>
                           Download
                        </Button>
                     </Stack>
                  </Box>

                  <Box
                     sx={{
                        width: { xs: '90%', sm: '47%', md: '31%' },
                        margin: { xs: '15px auto', md: '0' },
                     }}>
                     <Typography
                        variant='body1'
                        sx={{
                           textAlign: 'center',
                           fontSize: '18px',
                           marginBottom: '15px',
                        }}>
                        Valid & Not Exist Emails
                     </Typography>
                     <Typography
                        variant='h2'
                        sx={{
                           textAlign: 'center',
                           marginBottom: '25px',
                           fontWeight: '600',
                           color: theme.palette.primary.main,
                        }}>
                        {validNotExisEmailsList.length -
                           (importMethod === 'drag' ? 1 : 0)}
                     </Typography>
                     <Stack spacing={1} direction='column'>
                        <Button
                           disabled={validNotExisEmailsList.length === 1}
                           size='small'
                           disableElevation
                           variant='contained'
                           onClick={() =>
                              handleOpenAddModal(validNotExisEmailsList)
                           }>
                           Add to Contact
                        </Button>
                        <Button
                           disabled={validNotExisEmailsList.length === 1}
                           size='small'
                           disableElevation
                           variant='outlined'
                           onClick={() => {
                              downloadFileHandler(validNotExisEmailsList)
                           }}>
                           Download
                        </Button>
                     </Stack>
                  </Box>

                  <Box
                     sx={{
                        width: { xs: '90%', sm: '47%', md: '31%' },
                        margin: { xs: '15px auto', md: '0' },
                     }}>
                     <Typography
                        variant='body1'
                        sx={{
                           textAlign: 'center',
                           fontSize: '18px',
                           marginBottom: '15px',
                        }}>
                        Not Exist Emails
                     </Typography>
                     <Typography
                        variant='h2'
                        sx={{
                           textAlign: 'center',
                           marginBottom: '25px',
                           fontWeight: '600',
                           color: theme.palette.primary.main,
                        }}>
                        {notExistEmailsList.length -
                           (importMethod === 'drag' ? 1 : 0)}
                     </Typography>
                     <Stack spacing={1} direction='column'>
                        <Button
                           disabled={notExistEmailsList.length === 1}
                           size='small'
                           disableElevation
                           variant='contained'
                           onClick={() =>
                              handleOpenAddModal(notExistEmailsList)
                           }>
                           Add to Contact
                        </Button>
                        <Button
                           disabled={notExistEmailsList.length === 1}
                           size='small'
                           disableElevation
                           variant='outlined'
                           onClick={() => {
                              downloadFileHandler(notExistEmailsList)
                           }}>
                           Download
                        </Button>
                     </Stack>
                  </Box>
                  <ModalAddToContact
                     rows={modalRows}
                     open={showAddModal}
                     handleClose={handleCloseAddModal}
                  />
               </Stack>
               <Stack
                  sx={{ width: '100%', marginTop: '25px' }}
                  direction='row'
                  justifyContent='flex-end'
                  spacing={2}>
                  <Button
                     disableElevation
                     variant='outlined'
                     sx={{ textTransform: 'uppercase', width: 'fit-content' }}
                     onClick={() => downloadFileHandler(result)}>
                     Download All
                  </Button>
                  <Button
                     disableElevation
                     variant='contained'
                     sx={{ textTransform: 'uppercase', width: 'fit-content' }}
                     onClick={() => {
                        console.log('v', validEmailsList)
                        console.log('v & not', validNotExisEmailsList)
                        console.log('not', notExistEmailsList)
                     }}>
                     Finish
                  </Button>
               </Stack>
            </Stack>
         </Stack>
      </Box>
   )
}

const ModalImportList = ({ open, handleClose }) => {
   const [currentStep, setCurrentStep] = useState(0)
   const [importMethod, setImportMethod] = useState('write') // accept drag and write
   const [file, setFile] = useState(null)
   const [rows, setRows] = useState([])
   const [emails, setEmails] = useState([])
   const [selectedRow, setSelectedRow] = useState('')
   const [progress, setProgess] = useState(0)
   const [result, setResult] = useState([])

   const verifyEmail = async (index, rows, iter) => {
      const result = []

      if (importMethod === 'drag') {
         for (let i = 0; i < iter; i++) {
            const email = rows[i + index]
            try {
               result.push(
                  axios.get(
                     `https://appapi.openflow.pro/verify?email=${email[selectedRow]}`
                  )
               )
            } catch (error) {}
         }
      } else {
         rows.map(email => {
            try {
               result.push(
                  axios.get(`https://appapi.openflow.pro/verify?email=${email}`)
               )
            } catch (error) {}
         })
      }

      return Promise.all(result)
   }

   const StartFilteringEmails = async () => {
      // for (const r of rows) {
      let rowss = importMethod === 'drag' ? rows : emails
      let newRows = [...emails.map(email => [email])]
      console.log('newRows=====>', newRows)

      let counter = 0
      for (var i = 0; i < rowss.length; i += 25) {
         const rs = await verifyEmail(i, rowss, 25)
         console.log('rs===>', rs)
         for (var ii = 0; ii < rs.length; ii += 1) {
            counter++
            const status = rs[ii].data.valid
            let res = ''
            if (i + ii === 0 && importMethod === 'drag') {
               res = 'Result'
            } else if (status === true) {
               res = 'Exist'
            } else {
               if (!rs[ii].data.validators.disposable.valid) {
                  res = rs[ii].data.validators.disposable.reason
               }
               if (!rs[ii].data.validators.smtp.valid) {
                  res = rs[ii].data.validators.smtp.reason
               }
               if (!rs[ii].data.validators.typo.valid) {
                  res = rs[ii].data.validators.typo.reason
               }
               if (!rs[ii].data.validators.mx.valid) {
                  res = rs[ii].data.validators.mx.reason
               }
               if (!rs[ii].data.validators.regex.valid) {
                  res = rs[ii].data.validators.regex.reason
               }
            }
            if (importMethod === 'drag') {
               rowss[i + ii].splice(selectedRow + 1, 0, res)
            } else {
               newRows[i + ii].splice(1, 0, res)
            }
            setProgess((counter / rowss.length) * 100)
         }
      }

      console.log('rowss ===>', newRows)
      setResult(importMethod === 'drag' ? rowss : newRows)
      console.log('result===>', result)
   }

   useEffect(() => {
      if (progress === 100) {
         setCurrentStep(4)
      }
   }, [progress])

   const downloadFileHandler = data => {
      var workbook = utils.book_new()
      var worksheet = utils.aoa_to_sheet(data)
      utils.book_append_sheet(workbook, worksheet, 'sheet1', true)
      var wsnames = workbook.SheetNames
      console.log({ wsnames })
      writeFile(workbook, 'result.xlsx')
   }

   const handleCloseSteps = () => {
      setFile(null)
      setRows([])
      setCurrentStep(0)
      setImportMethod('write')
      setSelectedRow('')
      setProgess(0)
      setResult([])
      handleClose()
   }

   return (
      <Modal
         open={open}
         onClose={handleCloseSteps}
         sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
         }}>
         <>
            {currentStep === 0 && (
               <SourceStep
                  importMethod={importMethod}
                  handleChangeMethod={method => {
                     setEmails([])
                     setRows([])
                     setFile(null)
                     setImportMethod(method)
                  }}
                  file={file}
                  emailsArr={emails}
                  handleEmail
                  handleFileChange={data => setFile(data)}
                  handleRowsChange={data => setRows(data)}
                  handleEmailsChange={data => setEmails(data)}
                  handleClose={handleCloseSteps}
                  handleNextStep={() => {
                     if (importMethod === 'drag' && file === null) return
                     else if (importMethod === 'write' && emails.length === 0) {
                        return console.log('est')
                     } else {
                        console.log('rows =>', rows)
                        console.log('emails =>', emails)
                        if (importMethod === 'drag') setCurrentStep(1)
                        else setCurrentStep(2)
                     }
                  }}
               />
            )}
            {currentStep === 1 && (
               <ColumnStep
                  selectedRow={selectedRow}
                  handleClose={handleCloseSteps}
                  rowsHeader={rows[0]}
                  handleNextStep={() => {
                     if (selectedRow === '') return
                     setCurrentStep(2)
                  }}
                  handleBackStep={() => setCurrentStep(0)}
                  handleSelectedRowChange={data => setSelectedRow(data)}
               />
            )}
            {currentStep === 2 && (
               <ConfirmStep
                  handleClose={handleCloseSteps}
                  handleBackStep={() => {
                     importMethod === 'drag'
                        ? setCurrentStep(1)
                        : setCurrentStep(0)
                  }}
                  handleStart={() => {
                     setCurrentStep(3)
                     StartFilteringEmails()
                  }}
                  selectedRow={selectedRow}
                  rows={rows}
                  emails={emails}
               />
            )}

            {currentStep === 3 && (
               <VerificationStep
                  handleClose={handleCloseSteps}
                  handleCancelStep={() => console.log('cancel')}
                  progress={progress}
                  handleChangeProgress={data => setProgess(data)}
               />
            )}

            {currentStep === 4 && (
               <RapportStep
                  importMethod={importMethod}
                  handleClose={handleCloseSteps}
                  result={result}
                  downloadFileHandler={data => downloadFileHandler(data)}
               />
            )}

            {/* {currentStep === 5 && <AddToListStep handleClose={handleCloseSteps} />} */}
         </>
      </Modal>
   )
}

export default ModalImportList
