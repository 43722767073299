import {
   Box,
   Button,
   Divider,
   FormControl,
   InputLabel,
   MenuItem,
   Modal,
   Select,
   Stack,
   TextField,
   Typography,
   useTheme,
} from '@mui/material'
import React, { useState } from 'react'
import data from '../../../Data/data'

const ModalAddList = ({ open, handleClose }) => {
   const theme = useTheme()
   const [folder, setFolder] = useState('')
   const [listName, setListName] = useState('')

   return (
      <Modal open={open} onClose={handleClose}>
         <Box
            sx={{
               width: '100%',
               height: '100%',
               backgroundColor: '#FFF',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
            }}>
            <Stack
               spacing={2}
               sx={{
                  width: '100%',
                  height: '100%',
                  padding: '20px',
                  overflowY: 'scroll',
               }}>
               <Stack
                  spacing={2}
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'>
                  <Typography variant='h5'>Ajouter une liste</Typography>
                  <Button
                     onClick={handleClose}
                     variant='outlined'
                     size='medium'>
                     Annuler
                  </Button>
               </Stack>
               <Divider sx={{ margin: '15px 0' }} />
               <Stack alignItems='center'>
                  <Stack
                     spacing={2}
                     justifyContent='center'
                     alignItems='flex-end'
                     sx={{
                        width: { xs: '100%', sm: '90%', md: '70%' },
                        padding: '30px 0',
                        margin: '0 auto',
                     }}>
                     <FormControl size='small' sx={{ width: '100%' }}>
                        <InputLabel id='select-folder'>Folders</InputLabel>
                        <Select
                           fullWidth
                           label='Folders'
                           size='small'
                           value={folder}
                           onChange={e => setFolder(e.target.value)}>
                           {data.folders.map(folder => (
                              <MenuItem key={folder.id} value={folder.id}>
                                 {folder.name}
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                     <TextField
                        fullWidth
                        size='small'
                        variant='outlined'
                        label='Nom de la liste'
                        value={listName}
                        onChange={e => setListName(e.target.value)}
                        sx={{ backgroundColor: '#FFF' }}
                     />
                     <Button
                        disableElevation
                        disableTouchRipple
                        variant='contained'
                        sx={{
                           width: 'fit-content',
                           backgroundColor: theme.palette.primary.light,
                        }}>
                        Ajouter
                     </Button>
                  </Stack>
               </Stack>
            </Stack>
         </Box>
      </Modal>
   )
}

export default ModalAddList
