import { Autocomplete, Box, Button, IconButton, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";

const DefaultLang = ({ data, onclick, languages, onChange }) => {
  const [defaultLang, setDefaultLang] = useState(languages[0]);

  return (
    <Stack
      sx={{
        height: "fit-content",
        borderRight: "1px solid #000",
        width: { xs: "300px", md: "35%" },
      }}
    >
      <Box
        sx={{
          width: "100%",
          minHeight: "60px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#fff",
          borderBottom: "1px solid #000",
          padding: "0 10px",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "3",
        }}
      >
        <Autocomplete
          value={defaultLang}
          onChange={(e, value) => setDefaultLang(value)}
          inputValue={defaultLang}
          onInputChange={(e, value) => setDefaultLang(value)}
          options={languages}
          size="small"
          sx={{ width: "100%" }}
          renderInput={(params) => <TextField {...params} label="Enter Language" />}
        />
      </Box>
      {Object.keys(data).map((item, index) => (
        <Box
          id={`box-${index}`}
          key={index}
          sx={{
            width: "100%",
            minHeight: "60px",
            backgroundColor: "#fff",
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid rgba(0,0,0,0.2)",
            justifyContent: "space-between",
            gap: "5px",
            padding: "0 10px",
          }}
        >
          <TextField
            size="small"
            fullWidth
            label={item}
            sx={{ display: "none" }}
            defaultValue={data[item]}
            onBlur={({ target }) => onChange(target.value, item)}
          />

          <Typography
            sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}
            variant="body2"
          >
            {data[item]}
          </Typography>

          <IconButton
            size="small"
            onClick={(e) => {
              let box = document.querySelector(`#box-${index}`);
              let para = box.getElementsByTagName("p")[0];
              box.firstElementChild.style.display = "block";
              para.style.display = "none";
            }}
          >
            <EditIcon size="inherit" />
          </IconButton>
        </Box>
      ))}
      <Box
        sx={{
          width: "100%",
          minHeight: "60px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#fff",
          padding: "0 10px",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "3",
        }}
      >
        <Button component="a" onClick={onclick} disableElevation size="small" variant="contained">
          Download
        </Button>
      </Box>
    </Stack>
  );
};

export default DefaultLang;
