import {
   Button,
   Divider,
   IconButton,
   Link,
   Modal,
   styled,
   TextField,
   Tooltip,
   tooltipClasses,
   Typography,
   useMediaQuery,
} from '@mui/material'
import axios from 'axios'
import { Box, Stack, useTheme } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'

import InfoIcon from '@mui/icons-material/Info'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'

const HtmlTooltip = styled(({ className, ...props }) => (
   <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
   [`& .${tooltipClasses.tooltip}`]: {
      padding: '10px',
      backgroundColor: 'rgba(15, 44, 103, 0.9)',
      color: '#fff',
      borderRadius: '10px',
   },
   [`& .${tooltipClasses.arrow}`]: {
      color: 'rgba(15, 44, 103, 0.9)',
   },
   [`& .${tooltipClasses.tooltipPlacementTop}`]: {
      top: '10px',
   },
}))

const Single = () => {
   const theme = useTheme()
   const [email, setEmail] = useState('')
   const [result, setResult] = useState(null)
   const [reason, setReason] = useState('')
   const [history, setHistory] = useState([])
   const verifiedEmail = useRef('')
   const matchUpSm = useMediaQuery(theme => theme.breakpoints.up('sm'))

   const handleEmailChange = e => {
      setEmail(e.target.value)
   }

   const verifyEmail = async email => {
      const result = []
      try {
         result.push(
            axios.get(`https://appapi.openflow.pro/verify?email=${email}`)
         )
      } catch (error) {}

      return Promise.all(result)
   }

   const StartFilteringEmails = async () => {
      const rs = await verifyEmail(email)

      const status = rs[0].data.valid
      let res = ''
      if (status === true) {
         res = 'Exist'
      } else {
         if (!rs[0].data.validators.disposable.valid) {
            res = rs[0].data.validators.disposable.reason
         }
         if (!rs[0].data.validators.smtp.valid) {
            res = rs[0].data.validators.smtp.reason
         }
         if (!rs[0].data.validators.typo.valid) {
            res = rs[0].data.validators.typo.reason
         }
         if (!rs[0].data.validators.mx.valid) {
            res = rs[0].data.validators.mx.reason
         }
         if (!rs[0].data.validators.regex.valid) {
            res = rs[0].data.validators.regex.reason
         }
      }
      console.log(rs[0].data)
      setResult(rs[0].data)
      setReason(res)
   }

   const verifyHandler = async () => {
      verifiedEmail.current = email
      setEmail('')
      StartFilteringEmails()
   }

   useEffect(() => {
      let id =
         history.length !== 0
            ? Math.max(...history.map(item => item.id)) + 1
            : 0
      if (result === null) return
      setHistory([
         ...history,
         {
            id,
            email: verifiedEmail.current,
            valid: result?.valid,
            reason,
            date: new Date().toJSON().slice(0, 10),
         },
      ])
      console.log(history)
   }, [result])

   return (
      <Box
         sx={{
            width: '100%',
            minHeight: 'calc(100vh - 80px)',
            padding: '40px 0',
            backgroundColor: theme.palette.grey[100],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
         }}>
         <Stack
            spacing={2}
            sx={{
               width: '100%',
               height: '100%',
               justifyContent: 'flex-start',
               alignItems: 'center',
            }}>
            <Stack
               sx={{
                  width: { xs: '90%', md: '70%', lg: '50%' },
                  borderRadius: '10px',
                  backgroundColor: '#FFF',
                  padding: '30px 30px 40px 30px',
               }}
               spacing={2}>
               <Typography
                  variant='h5'
                  sx={{
                     fontWeight: '600',
                     color: theme.palette.grey[800],
                     display: 'flex',
                     alignItems: 'center',
                  }}>
                  Email Verifier
                  <HtmlTooltip
                     arrow
                     placement='right'
                     title="The Email Verifier verifies individual emails in real-time, Enter an email and we'll verify it.">
                     <InfoIcon
                        fontSize='inherit'
                        sx={{
                           color: theme.palette.primary.dark,
                           marginLeft: '10px',
                        }}
                     />
                  </HtmlTooltip>
               </Typography>
               <Divider sx={{ margin: '15px 0' }} />
               <Stack direction='row' spacing={1} sx={{ width: '100%' }}>
                  <TextField
                     id='email input'
                     variant='outlined'
                     placeholder='elon.musk@spacex.com'
                     value={email}
                     onChange={handleEmailChange}
                     fullWidth
                     required
                     type='email'
                     sx={{ '& .MuiInputBase-input ': { padding: '10px' } }}
                  />
                  <IconButton
                     onClick={verifyHandler}
                     size='small'
                     aria-label='search'
                     sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: '#FFF',
                        borderRadius: '7px',
                        padding: '0 15px',
                        '&:hover': {
                           backgroundColor: theme.palette.primary.dark,
                        },
                     }}>
                     <SearchIcon fontSize='inherit' />
                  </IconButton>
               </Stack>
            </Stack>

            {result && (
               <Stack
                  sx={{
                     width: { xs: '90%', md: '70%', lg: '50%' },
                     borderRadius: '10px',
                     backgroundColor: '#FFF',
                     padding: '30px',
                  }}
                  spacing={2}
                  direction={matchUpSm ? 'row' : 'column'}
                  justifyContent='space-between'
                  alignItems={matchUpSm ? 'center' : 'flex-end'}>
                  <Typography
                     sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        fontSize: '18px',
                        fontWeight: '700',
                        color: theme.palette.primary.main,
                        width: matchUpSm ? 'unset' : '100%',
                     }}>
                     {!result.valid ? (
                        <CloseIcon
                           sx={{
                              marginRight: '10px',
                              backgroundColor: '#E74646',
                              color: '#FFF',
                              width: '34px',
                              padding: '3px 0',
                              borderRadius: '5px',
                           }}
                        />
                     ) : (
                        <CheckIcon
                           sx={{
                              marginRight: '10px',
                              backgroundColor: '#54B435',
                              color: '#FFF',
                              width: '34px',
                              padding: '3px 0',
                              borderRadius: '5px',
                           }}
                        />
                     )}
                     {verifiedEmail.current}
                  </Typography>

                  <Stack
                     justifyContent='center'
                     alignItems='center'
                     direction='row'
                     spacing={2}>
                     <Typography sx={{ fontWeight: '700' }}>
                        {result.valid ? 'Exist' : reason}
                     </Typography>
                     <Tooltip title='Add This email to contact'>
                        <Button
                           disableElevation
                           variant='outlined'
                           size='small'
                           sx={{
                              textTransform: 'capitalize',
                              fontSize: '14px',
                              padding: '3px',
                           }}>
                           Add
                        </Button>
                     </Tooltip>
                  </Stack>
               </Stack>
            )}

            {history && (
               <Stack
                  sx={{
                     width: { xs: '90%', md: '70%', lg: '50%' },
                     borderRadius: '10px',
                     backgroundColor: '#FFF',
                     padding: '30px 30px 40px 30px',
                  }}
                  spacing={2}>
                  <Typography
                     sx={{
                        fontWeight: '600',
                        color: theme.palette.grey[800],
                        display: 'flex',
                        alignItems: 'center',
                     }}
                     variant='h5'>
                     History
                  </Typography>
                  <Divider />
                  <Stack spacing={2}>
                     {history.map(item => (
                        <Stack
                           key={item.id}
                           sx={{
                              width: '100%',
                              borderRadius: '10px',
                           }}
                           direction='row'
                           justifyContent='space-between'
                           alignItems='flex-start'>
                           <Stack
                              justifyContent='center'
                              alignItems='flex-start'>
                              <Typography
                                 sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    fontSize: '17px',
                                    fontWeight: '700',
                                    color: theme.palette.grey[700],
                                 }}>
                                 {!item.valid ? (
                                    <CloseIcon
                                       sx={{
                                          marginRight: '10px',
                                          backgroundColor: '#E74646',
                                          color: '#FFF',
                                          width: '34px',
                                          padding: '3px 0',
                                          borderRadius: '5px',
                                       }}
                                    />
                                 ) : (
                                    <CheckIcon
                                       sx={{
                                          marginRight: '10px',
                                          backgroundColor: '#54B435',
                                          color: '#FFF',
                                          width: '34px',
                                          padding: '3px 0',
                                          borderRadius: '5px',
                                       }}
                                    />
                                 )}
                                 {item.email}
                              </Typography>
                              <Typography
                                 sx={{
                                    fontSize: '14px',
                                    color: theme.palette.grey[500],
                                    marginLeft: '43px',
                                 }}>
                                 {item.date}
                              </Typography>
                           </Stack>
                           <Typography
                              sx={{ fontWeight: '700', fontSize: '15px' }}>
                              {item.valid ? 'Exist' : item.reason}
                           </Typography>
                        </Stack>
                     ))}
                  </Stack>
               </Stack>
            )}
         </Stack>
      </Box>
   )
}

export default Single
