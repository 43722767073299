import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  useMediaQuery,
} from "@mui/material";

import { Link as routerLink } from "react-router-dom";

import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ListIcon from "@mui/icons-material/List";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import FlashOnIcon from "@mui/icons-material/FlashOn";

import Person2Icon from "@mui/icons-material/Person2";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import HelpIcon from "@mui/icons-material/Help";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

const SettingsDashBoard = ({ open, handleClose }) => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const [scroll, setScroll] = useState();

  const getScroll = () => {
    setScroll(window.scrollY);
  };

  window.addEventListener("scroll", getScroll);

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          minHeight: "calc(100vh - 70px)",
          position: scroll > 70 ? "fixed" : "absolute",
          backgroundColor: theme.palette.grey[200],
          border: "none",
          top: 0,
          left: 0,
        },
        "& .MuiDrawer-paper": {
          width: "240px",
        },
      }}
      anchor="left"
      variant={matchDownMd ? "persistent" : "permanent"}
      onClose={handleClose}
      open={matchDownMd ? open : true}
    >
      <Stack spacing={1}>
        <Stack alignItems="flex-end">
          {matchDownMd && (
            <IconButton
              sx={{
                margin: "5px",
                width: "fit-content",
                pading: "10px",
                backgroundColor: theme.palette.grey[100],
              }}
              onClick={handleClose}
            >
              <KeyboardDoubleArrowLeftIcon fontSize="meduim" />
            </IconButton>
          )}
        </Stack>
        <Stack>
          <List disablePadding>
            <ListItem disablePadding>
              <ListItemButton
                component={routerLink}
                to="/email-checker/profile"
                alignItems="center"
                disableGutters
                sx={{
                  padding: "8px 15px",
                  borderRadius: "5px",
                }}
              >
                <Person2Icon
                  sx={{
                    width: "fit-content",
                    minWidth: "unset",
                    marginRight: "10px",
                    color: theme.palette.primary.dark,
                    fontSize: "30px",
                  }}
                />
                <ListItemText
                  primary="My profile"
                  primaryTypographyProps={{
                    color: theme.palette.grey[900],
                    fontSize: "16px",
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={routerLink}
                to="billing"
                alignItems="center"
                disableGutters
                sx={{
                  padding: "8px 15px",
                  borderRadius: "5px",
                }}
              >
                <CreditCardIcon
                  sx={{
                    width: "fit-content",
                    minWidth: "unset",
                    marginRight: "10px",
                    color: theme.palette.primary.dark,
                    fontSize: "30px",
                  }}
                />
                <ListItemText
                  primary="Billing"
                  primaryTypographyProps={{
                    color: theme.palette.grey[900],
                    fontSize: "16px",
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={routerLink}
                to="integrations"
                alignItems="center"
                disableGutters
                sx={{
                  padding: "8px 15px",
                  borderRadius: "5px",
                }}
              >
                <AccountBalanceWalletIcon
                  sx={{
                    width: "fit-content",
                    minWidth: "unset",
                    marginRight: "10px",
                    color: theme.palette.primary.dark,
                    fontSize: "30px",
                  }}
                />
                <ListItemText
                  primary="Integrations"
                  primaryTypographyProps={{
                    color: theme.palette.grey[900],
                    fontSize: "16px",
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={routerLink}
                to="referrals"
                alignItems="center"
                disableGutters
                sx={{
                  padding: "8px 15px",
                  borderRadius: "5px",
                }}
              >
                <MapsUgcIcon
                  sx={{
                    width: "fit-content",
                    minWidth: "unset",
                    marginRight: "10px",
                    color: theme.palette.primary.dark,
                    fontSize: "30px",
                  }}
                />
                <ListItemText
                  primary="Referrals"
                  primaryTypographyProps={{
                    color: theme.palette.grey[900],
                    fontSize: "16px",
                  }}
                />
              </ListItemButton>
            </ListItem>
            {/* <ListItem disablePadding>
                     <ListItemButton
                        component={routerLink}
                        to='help'
                        alignItems='center'
                        disableGutters
                        sx={{
                           padding: '8px 15px',
                           borderRadius: '5px',
                        }}>
                        <HelpIcon
                           sx={{
                              width: 'fit-content',
                              minWidth: 'unset',
                              marginRight: '10px',
                              color: theme.palette.primary.dark,
                              fontSize: '30px',
                           }}
                        />
                        <ListItemText
                           primary='Help'
                           primaryTypographyProps={{
                              color: theme.palette.grey[900],
                              fontSize: '16px',
                           }}
                        />
                     </ListItemButton>
                  </ListItem> */}
            <ListItem disablePadding>
              <ListItemButton
                component={routerLink}
                to="/email-checker/login"
                alignItems="center"
                disableGutters
                sx={{
                  padding: "8px 15px",
                  borderRadius: "5px",
                }}
              >
                <ExitToAppIcon
                  sx={{
                    width: "fit-content",
                    minWidth: "unset",
                    marginRight: "10px",
                    color: theme.palette.primary.dark,
                    fontSize: "30px",
                  }}
                />
                <ListItemText
                  primary="Sign out"
                  primaryTypographyProps={{
                    color: theme.palette.grey[900],
                    fontSize: "16px",
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default SettingsDashBoard;
