import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Headers from "./Table/Headers";
import DefaultLang from "./Table/DefaultLang";
import AddLang from "./Table/AddLang";
import { Stack } from "@mui/material";

const languagesList = [
  "English",
  "Afrikaans",
  "Albanian",
  "Amharic",
  "Arabic",
  "Armenian",
  "Azerbaijani",
  "Basque",
  "Belarusian",
  "Bengali",
  "Bosnian",
  "Bulgarian",
  "Catalan",
  "Cebuano",
  "Chichewa",
  "Chinese ",
  "Corsican",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "English",
  "Esperanto",
  "Estonian",
  "Filipino",
  "Finnish",
  "French",
  "Frisian",
  "Galician",
  "Georgian",
  "German",
  "Greek",
  "Gujarati",
  "Haitian Creole",
  "Hausa",
  "Hawaiian",
  "Hebrew",
  "Hindi",
  "Hmong",
  "Hungarian",
  "Icelandic",
  "Igbo",
  "Indonesian",
  "Irish",
  "Italian",
  "Japanese",
  "Javanese",
  "Kannada",
  "Kazakh",
  "Khmer",
  "Korean",
  "Kurdish (Kurmanji)",
  "Kyrgyz",
  "Lao",
  "Latin",
  "Latvian",
  "Lithuanian",
  "Luxembourgish",
  "Macedonian",
  "Malagasy",
  "Malay",
  "Malayalam",
  "Maltese",
  "Maori",
  "Marathi",
  "Mongolian",
  "Myanmar",
  "Nepali",
  "Norwegian",
  "Pashto",
  "Persian",
  "Polish",
  "Portuguese",
  "Punjabi",
  "Romanian",
  "Russian",
  "Samoan",
  "Scots Gaelic",
  "Serbian",
  "Sesotho",
  "Shona",
  "Sindhi",
  "Sinhala",
  "Slovak",
  "Slovenian",
  "Somali",
  "Spanish",
  "Sundanese",
  "Swahili",
  "Swedish",
  "Tajik",
  "Tamil",
  "Telugu",
  "Thai",
  "Turkish",
  "Ukranian",
  "Urdu",
  "Uzbek",
  "Vietnamese",
  "Welsh",
  "Xhosa",
  "Yiddish",
  "Yoruba",
  "Zulu",
];

const TableData = () => {
  const location = useLocation();
  const name = location.state.name;

  const [data, setData] = useState([]);
  const [newData, setNewData] = useState([]);

  const [defaultLang, setDefaultLang] = useState(languagesList[0]);

  useEffect(() => {
    setData(location.state.data);
  }, [location.state.data]);

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      sx={{
        position: "relative",
        margin: "15px 0",
        height: "85vh",
        border: "1px solid #D8D8D8",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        borderRadius: "10px",
        width: { xs: "90%", md: "900px", lg: "1100px" },
        overflow: "scroll",
      }}
    >
      <Headers headers={Object.keys(data)} />
      <DefaultLang
        data={data}
        languages={languagesList}
        onChange={(value, head) => {
          setData((prvData) => {
            const prev = { ...prvData };
            prev[head] = value;
            return prev;
          });
        }}
        onclick={(e) => {
          console.log(data);
          let dataStr =
            "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data, null, 2));
          e.target.setAttribute("href", dataStr);
          e.target.setAttribute("download", `${name}`);
        }}
      />
      <AddLang
        data={Object.keys(data)}
        languages={languagesList.filter((item) => item !== defaultLang)}
        onchange={(value, head) => {
          setNewData((prvData) => {
            const prev = { ...prvData };
            prev[head] = value;
            return prev;
          });
        }}
        onclick={(e) => {
          let dataStr =
            "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(newData, null, 2));
          e.target.setAttribute("href", dataStr);
          e.target.setAttribute("download", `${name}`);
        }}
      />
    </Stack>
  );
};

export default TableData;
