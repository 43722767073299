import React from 'react'
import {
   Box,
   Button,
   Divider,
   Modal,
   Stack,
   TextField,
   Typography,
   useTheme,
} from '@mui/material'

const ModalAddSegment = ({ open, handleClose }) => {
   const theme = useTheme()
   return (
      <Modal open={open} onClose={handleClose}>
         <Box
            sx={{
               width: '100%',
               height: '100%',
               backgroundColor: '#FFF',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
            }}>
            <Stack
               spacing={2}
               sx={{
                  width: '100%',
                  height: '100%',
                  padding: '20px',
                  overflowY: 'scroll',
               }}>
               <Stack
                  spacing={2}
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'>
                  <Typography variant='h5'>Ajouter un segment</Typography>
                  <Stack spacing={2} direction='row'>
                     <Button
                        onClick={handleClose}
                        variant='outlined'
                        size='medium'>
                        Annuler
                     </Button>
                     <Button disableElevation variant='contained' size='medium'>
                        Enregistrer
                     </Button>
                  </Stack>
               </Stack>
               <Divider sx={{ margin: '15px 0' }} />
               <Stack alignItems='center'>
                  <Stack
                     spacing={2}
                     justifyContent='center'
                     alignItems='center'
                     sx={{
                        width: { xs: '100%', sm: '90%', md: '70%' },
                        padding: '30px 0',
                        margin: '0 auto',
                     }}>
                     <Typography>Filter here</Typography>
                  </Stack>
               </Stack>
            </Stack>
         </Box>
      </Modal>
   )
}

export default ModalAddSegment
