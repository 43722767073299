import { useTheme } from '@emotion/react'
import {
   Button,
   ClickAwayListener,
   Link,
   List as MuiList,
   ListItem,
   ListItemButton,
   ListItemIcon,
   ListItemText,
   Popover,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   TextField,
   Stack,
   Typography,
} from '@mui/material'
import React, { useState } from 'react'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import PersonIcon from '@mui/icons-material/Person'
import BuildIcon from '@mui/icons-material/Build'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import AddIcon from '@mui/icons-material/Add'
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt'
import ReplyIcon from '@mui/icons-material/Reply'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

const CustomTable = ({
   headers,
   data,
   renameCell = false,
   handelDeleteModal,
   handelAddSubModal,
   handleDuplicateModal,
   handleListSettingsModal,
   handleImportFile,
}) => {
   // header => id nom nombre de contacts action
   // The rename cell contains the name of the cell that can be renamed in the table
   const theme = useTheme()
   const [rename, setRename] = useState(false) // This handle the show&hide of the input of changing the name of the list
   const [anchorEl, setAnchorEl] = useState(null)

   const handleRename = () => {
      setRename(true)
   }

   const handleRenameClose = () => {
      setRename(false)
   }
   const handleActionsClose = () => {
      setAnchorEl(null)
   }
   // This is the popover that shows on click on the actions button
   const ActionsPopover = (
      <Popover
         elevation={0}
         id='actions-popover'
         open={Boolean(anchorEl)}
         anchorEl={anchorEl}
         onClose={handleActionsClose}
         anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
         }}
         sx={{
            '& .MuiPaper-root': {
               boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            },
         }}>
         <MuiList disablePadding>
            <ListItem disablePadding>
               <ListItemButton sx={{ padding: '2px 6px' }} disableTouchRipple>
                  <ListItemIcon sx={{ minWidth: '0', marginRight: '5px' }}>
                     <PersonIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText
                     primary="Gestionnaire d'abonnés"
                     primaryTypographyProps={{
                        fontSize: '12px',
                     }}></ListItemText>
               </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
               <ListItemButton
                  sx={{ padding: '2px 6px' }}
                  disableTouchRipple
                  onClick={() => {
                     handleActionsClose()
                     handleListSettingsModal()
                  }}>
                  <ListItemIcon sx={{ minWidth: '0', marginRight: '5px' }}>
                     <BuildIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText
                     primary='Paramètres de la liste'
                     primaryTypographyProps={{
                        fontSize: '12px',
                     }}></ListItemText>
               </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
               <ListItemButton
                  sx={{ padding: '2px 6px' }}
                  disableTouchRipple
                  onClick={() => {
                     handleDuplicateModal()
                     handleActionsClose()
                  }}>
                  <ListItemIcon sx={{ minWidth: '0', marginRight: '5px' }}>
                     <ContentCopyIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText
                     primary='Dupliquer'
                     primaryTypographyProps={{
                        fontSize: '12px',
                     }}></ListItemText>
               </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
               <ListItemButton
                  sx={{ padding: '2px 6px' }}
                  disableTouchRipple
                  onClick={() => {
                     handelAddSubModal()
                     handleActionsClose()
                  }}>
                  <ListItemIcon sx={{ minWidth: '0', marginRight: '5px' }}>
                     <AddIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText
                     primary='Ajouter un abonné'
                     primaryTypographyProps={{
                        fontSize: '12px',
                     }}></ListItemText>
               </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
               <ListItemButton
                  sx={{ padding: '2px 6px' }}
                  disableTouchRipple
                  onClick={() => {
                     handleActionsClose()
                     handleImportFile()
                  }}>
                  <ListItemIcon sx={{ minWidth: '0', marginRight: '5px' }}>
                     <SystemUpdateAltIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText
                     primary='Importer des abonnés'
                     primaryTypographyProps={{
                        fontSize: '12px',
                     }}></ListItemText>
               </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
               <ListItemButton sx={{ padding: '2px 6px' }} disableTouchRipple>
                  <ListItemIcon sx={{ minWidth: '0', marginRight: '5px' }}>
                     <ReplyIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText
                     primary='Copier/coller les abonnés'
                     primaryTypographyProps={{
                        fontSize: '12px',
                     }}></ListItemText>
               </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
               <ListItemButton
                  sx={{ padding: '2px 6px' }}
                  disableTouchRipple
                  onClick={() => {
                     handleRename()
                     handleActionsClose()
                  }}>
                  <ListItemIcon sx={{ minWidth: '0', marginRight: '5px' }}>
                     <EditIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText
                     primary='Renommer'
                     primaryTypographyProps={{
                        fontSize: '12px',
                     }}></ListItemText>
               </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
               <ListItemButton
                  sx={{ padding: '2px 6px' }}
                  disableTouchRipple
                  onClick={() => {
                     handelDeleteModal()
                     handleActionsClose()
                  }}>
                  <ListItemIcon sx={{ minWidth: '0', marginRight: '5px' }}>
                     <DeleteIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText
                     primary='Supprimer'
                     primaryTypographyProps={{
                        fontSize: '12px',
                     }}></ListItemText>
               </ListItemButton>
            </ListItem>
         </MuiList>
      </Popover>
   )

   return (
      <TableContainer sx={{ marginTop: '30px' }}>
         <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead sx={{ backgroundColor: theme.palette.grey[100] }}>
               <TableRow>
                  {headers.map((head, index) => (
                     <TableCell
                        align={head === 'id' ? 'left' : 'right'}
                        key={index}
                        sx={{
                           padding: '10px 15px',
                           fontWeight: '600',
                           color: theme.palette.grey[500],
                           fontSize: '16px',
                        }}>
                        {head}
                     </TableCell>
                  ))}
               </TableRow>
            </TableHead>
            <TableBody>
               {data.map(list => (
                  <TableRow
                     key={list.id}
                     sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                     <TableCell
                        sx={{ padding: '8px 15px' }}
                        component='th'
                        scope='row'
                        align='left'>
                        #{list.id}
                     </TableCell>
                     <TableCell sx={{ padding: '8px 15px' }} align='right'>
                        {rename ? (
                           <ClickAwayListener
                              onClickAway={() => handleRenameClose()}>
                              <TextField
                                 size='small'
                                 defaultValue='Your First list'
                                 id='text-rename'
                                 inputProps={{
                                    style: {
                                       padding: '5px',
                                       fontSize: '12px',
                                    },
                                 }}
                              />
                           </ClickAwayListener>
                        ) : (
                           list.name
                        )}
                     </TableCell>
                     <TableCell sx={{ padding: '8px 15px' }} align='right'>
                        <Link
                           underline='none'
                           sx={{
                              color: theme.palette.grey[600],
                              cursor: 'pointer',
                              '&:hover ': {
                                 color: theme.palette.primary.light,
                              },
                           }}>
                           {list.numContacts} Contacts
                        </Link>
                     </TableCell>
                     <TableCell sx={{ padding: '8px 15px' }} align='right'>
                        <Button
                           onClick={event => setAnchorEl(event.currentTarget)}
                           disableElevation
                           disableTouchRipple
                           variant='contained'
                           size='small'
                           sx={{
                              fontSize: '12px',
                              backgroundColor: theme.palette.grey[200],
                              color: theme.palette.grey[900],
                              '&:hover': {
                                 backgroundColor: theme.palette.grey[300],
                              },
                           }}
                           endIcon={<ArrowDropDownIcon />}>
                           Actions
                        </Button>
                        {ActionsPopover}
                     </TableCell>
                  </TableRow>
               ))}
            </TableBody>
         </Table>
         {data.length === 0 && (
            <Stack
               spacing={1}
               sx={{ width: '100%', paddingTop: '20px' }}
               justifyContent='center'
               alignItems='center'>
               <Typography variant='h5'>Vous n'avez aucun item.</Typography>
               <Typography variant='body1'>
                  Veuillez créer un nouveau item.
               </Typography>
            </Stack>
         )}
      </TableContainer>
   )
}

export default CustomTable
