import { useTheme } from '@emotion/react'
import { Box, Button, Divider, Modal, Stack, Typography } from '@mui/material'
import React from 'react'

const ModalListSettings = ({ open, handleClose }) => {
   const theme = useTheme()

   return (
      <Modal open={open} onClose={handleClose}>
         <Box
            sx={{
               width: '100%',
               height: '100%',
               backgroundColor: '#FFF',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
            }}>
            <Stack
               spacing={2}
               sx={{
                  width: '100%',
                  height: '100%',
                  padding: '20px',
                  overflowY: 'scroll',
               }}>
               <Stack
                  spacing={2}
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'>
                  <Typography variant='h5'>Paramètres de la liste</Typography>
                  <Stack spacing={2} direction='row'>
                     <Button
                        disableElevation
                        onClick={handleClose}
                        variant='outlined'
                        size='medium'>
                        Annuler
                     </Button>
                     <Button
                        disableElevation
                        onClick={handleClose}
                        variant='contained'
                        size='medium'
                        sx={{ backgroundColor: theme.palette.primary.light }}>
                        Sauvegarder
                     </Button>
                  </Stack>
               </Stack>
               <Divider sx={{ margin: '15px 0' }} />
            </Stack>
         </Box>
      </Modal>
   )
}

export default ModalListSettings
