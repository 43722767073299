import React, { useState } from 'react'
import { Avatar, Button, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'

import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread'
import { useTheme } from '@emotion/react'
import ModalImportList from '../Components/Bulk/ModalImportList'

const Bulk = () => {
   const [open, setOpen] = useState(false)
   const handleClose = () => {
      setOpen(false)
   }

   const theme = useTheme()
   return (
      <Box
         sx={{
            width: '100%',
            height: 'calc(100vh - 70px)',
            backgroundColor: theme.palette.grey[100],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
         }}>
         <Stack
            sx={{
               width: { xs: '90%', md: '60%' },
               height: '70%',
               borderRadius: '10px',
               backgroundColor: '#FFF',
               padding: '20px',
               textAlign: 'center',
            }}
            alignItems='center'
            justifyContent='center'
            spacing={2}>
            <Avatar
               sx={{
                  backgroundColor: theme.palette.primary.dark,
                  width: '50px',
                  height: '50px',
                  fontSize: '26px',
               }}>
               <MarkAsUnreadIcon fontSize='inherit' />
            </Avatar>
            <Typography variant='h4' sx={{ color: theme.palette.grey[800] }}>
               Import a list to verify
            </Typography>
            <Typography
               sx={{ color: theme.palette.grey[500], fontSize: '20px' }}>
               Upload a list or connect an integration to start verifying your
               emails.
            </Typography>
            <Button
               onClick={() => setOpen(true)}
               disableElevation
               disableRipple
               variant='contained'
               sx={{ backgroundColor: theme.palette.primary.dark }}>
               Get Started
            </Button>
         </Stack>
         <ModalImportList open={open} handleClose={handleClose} />
      </Box>
   )
}

export default Bulk
