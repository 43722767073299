import { Box, Stack, Tab, Tabs, Typography } from '@mui/material'
import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import TabInformation from '../Components/Settings/Profile/TabInformation'
import TabPassword from '../Components/Settings/Profile/TabPassword'
import TabLegal from '../Components/Settings/Profile/TabLegal'

function TabPanel(props) {
   const { children, value, index, ...other } = props

   return (
      <Box
         sx={{ minHeight: '700px' }}
         role='tabpanel'
         hidden={value !== index}
         {...other}>
         {value === index && children}
      </Box>
   )
}

const Profile = () => {
   const [value, setValue] = React.useState(0)

   const handleChange = (event, newValue) => {
      setValue(newValue)
   }

   const handleChangeIndex = index => {
      setValue(index)
   }

   return (
      <Stack spacing={2} sx={{ marginTop: { xs: '30px', md: '0' } }}>
         <Typography variant='h4'>My profile</Typography>
         <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
               value={value}
               onChange={handleChange}
               aria-label='basic tabs example'>
               <Tab
                  label='Information'
                  sx={{ textTransform: 'capitalize', fontSize: '17px' }}
               />
               <Tab
                  label='Password'
                  sx={{ textTransform: 'capitalize', fontSize: '17px' }}
               />
               {/* <Tab label="Legal document" sx={{ textTransform: "capitalize", fontSize: "17px" }} /> */}
            </Tabs>
         </Box>
         <SwipeableViews
            axis='x'
            index={value}
            onChangeIndex={handleChangeIndex}>
            <TabPanel value={value} index={0}>
               <TabInformation />
            </TabPanel>
            <TabPanel value={value} index={1}>
               <TabPassword />
            </TabPanel>
            {/* <TabLegal value={value} index={2}>
          <TabInformation />
        </TabLegal> */}
         </SwipeableViews>
      </Stack>
   )
}

export default Profile
