import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import App from "./App/App";
import reportWebVitals from "./reportWebVitals";

const Index = () => {
  const theme = createTheme({
    palette: {
      grey: { main: "#EEEEEE" },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  );
};
export default Index;
reportWebVitals();
