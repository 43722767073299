import {
   Box,
   Button,
   FormControl,
   InputLabel,
   MenuItem,
   Modal,
   Select,
   TextField,
   Typography,
} from '@mui/material'
import React, { useState } from 'react'
import data from '../../../Data/data'
import { useTheme } from '@emotion/react'
import AddIcon from '@mui/icons-material/Add'

const ModalAddToContact = ({ open, handleClose, rows }) => {
   const [list, setList] = useState('')
   const [folder, setFolder] = useState('')
   const [newList, setNewList] = useState('')
   const theme = useTheme()

   return (
      <Modal
         open={open}
         onClose={handleClose}
         sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
         }}>
         <Box
            sx={{
               width: { xs: '80%', sm: '60%', md: '50%', lg: '30%' },
               padding: '20px',
               backgroundColor: '#fff',
               borderRadius: '15px',
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'flex-end',
            }}>
            <Typography
               sx={{
                  marginBottom: '10px',
                  color: theme.palette.primary.main,
                  fontSize: '18px',
                  width: '100%',
               }}>
               Select a list :
            </Typography>
            <FormControl
               size='small'
               sx={{ width: '100%', marginBottom: '30px' }}>
               <InputLabel id='select-label'>Lists</InputLabel>
               <Select
                  labelId='select-label'
                  fullWidth
                  label='Lists'
                  size='small'
                  value={list}
                  onChange={e => setList(e.target.value)}>
                  {data.lists.map(list => (
                     <MenuItem key={list.id} value={list.id}>
                        {list.name}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>

            <Typography
               sx={{
                  marginBottom: '10px',
                  color: theme.palette.primary.main,
                  fontSize: '18px',
                  width: '100%',
               }}>
               Or create new list :
            </Typography>
            <FormControl
               size='small'
               sx={{ width: '100%', marginBottom: '10px' }}>
               <InputLabel id='select-folder'>Folders</InputLabel>
               <Select
                  labelId='select-folder'
                  fullWidth
                  label='Folders'
                  size='small'
                  value={folder}
                  onChange={e => setFolder(e.target.value)}>
                  {data.folders.map(folder => (
                     <MenuItem key={folder.id} value={folder.id}>
                        {folder.name}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
            <FormControl
               size='small'
               sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '10px',
                  marginBottom: '30px',
               }}>
               <TextField
                  fullWidth
                  size='small'
                  label='List Name'
                  variant='outlined'
                  value={newList}
                  onChange={e => setNewList(e.target.value)}
               />
               <Button
                  variant='outlined'
                  startIcon={<AddIcon />}
                  sx={{ textTransform: 'capitalize' }}>
                  Create
               </Button>
            </FormControl>
            <Button disableElevation variant='contained' size='small'>
               Add to list
            </Button>
         </Box>
      </Modal>
   )
}

export default ModalAddToContact
