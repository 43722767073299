import {
   AppBar,
   Box,
   Divider,
   IconButton,
   InputAdornment,
   List,
   ListItem,
   ListItemAvatar,
   ListItemButton,
   ListItemText,
   Popover,
   Stack,
   Tab,
   Tabs,
   TextField,
   Typography,
   useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import SwipeableViews from 'react-swipeable-views'

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import FlashOnIcon from '@mui/icons-material/FlashOn'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'

function TabPanel(props) {
   const { children, value, index, ...other } = props

   return (
      <div
         role='tabpanel'
         hidden={value !== index}
         id={`full-width-tabpanel-${index}`}
         aria-labelledby={`full-width-tab-${index}`}
         {...other}>
         {value === index && (
            <Box sx={{ p: 3 }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   )
}
TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.number.isRequired,
   value: PropTypes.number.isRequired,
}

function a11yProps(index) {
   return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
   }
}

const LoadListPopover = ({ anchorEl, handleClose }) => {
   const [value, setValue] = useState(0)
   const theme = useTheme()

   const handleChange = (event, newValue) => {
      setValue(newValue)
   }

   const handleChangeIndex = index => {
      setValue(index)
   }

   const ShowListsPanel = (
      <List dense>
         <ListItem
            key={value}
            secondaryAction={<ArrowForwardIosIcon />}
            disablePadding>
            <ListItemButton>
               <ListItemAvatar>
                  <FolderOpenIcon />
               </ListItemAvatar>
               <ListItemText primary='First Folder' />
            </ListItemButton>
         </ListItem>
      </List>
   )

   return (
      <Popover
         open={Boolean(anchorEl)}
         anchorEl={anchorEl}
         onClose={handleClose}
         anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
         }}>
         <Stack
            spacing={2}
            sx={{
               padding: '15px',
               width: { xs: '240px', sm: '340px', md: '550px' },
               margin: '0 auto',
            }}
            direction='column'>
            <Stack spacing={1}>
               <TextField
                  fullWidth
                  label='Rechercher une liste / segment'
                  size='small'
                  InputLabelProps={{ style: { fontSize: '14px' } }}
                  InputProps={{
                     endAdornment: (
                        <InputAdornment position='end'>
                           <IconButton edge='end'>
                              <SearchOutlinedIcon />
                           </IconButton>
                        </InputAdornment>
                     ),
                  }}
               />
            </Stack>
            <Divider sx={{ margin: '10px 0 ' }} />
            <Box sx={{ bgcolor: 'background.paper' }}>
               <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor='secondary'
                  textColor='inherit'
                  aria-label='full width tabs example'
                  sx={{ height: '60px', minHeight: 'unset' }}>
                  <Tab
                     sx={{
                        padding: '10px',
                        fontSize: '14px',
                        textTransform: 'capitalize',
                     }}
                     label='Listes'
                     icon={<FormatListBulletedIcon />}
                     iconPosition='start'
                     {...a11yProps(0)}
                  />
                  <Tab
                     sx={{
                        padding: '10px',
                        fontSize: '14px',
                        textTransform: 'capitalize',
                     }}
                     label='Segments'
                     icon={<FlashOnIcon />}
                     iconPosition='start'
                     {...a11yProps(1)}
                  />
               </Tabs>
               <SwipeableViews
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={value}
                  onChangeIndex={handleChangeIndex}>
                  <TabPanel value={value} index={0} dir={theme.direction}>
                     {ShowListsPanel}
                  </TabPanel>
                  <TabPanel value={value} index={1} dir={theme.direction}>
                     Item Two
                  </TabPanel>
                  <TabPanel value={value} index={2} dir={theme.direction}>
                     Item Three
                  </TabPanel>
               </SwipeableViews>
            </Box>
         </Stack>
      </Popover>
   )
}

export default LoadListPopover
