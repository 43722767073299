import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import {
   Box,
   Button,
   FormControl,
   InputBase,
   InputLabel,
   Stack,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
} from '@mui/material'
import React from 'react'

const CustomInput = styled(InputBase)(({ theme }) => ({
   'label + &': {
      marginTop: 25,
      width: '100%',
   },
   '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: '#fcfcfb',
      border: '1px solid #ced4da',
      fontSize: 16,
      width: '100%',
      padding: '7.5px 12px',
      transition: theme.transitions.create([
         'border-color',
         'background-color',
         'box-shadow',
      ]),
      '&:focus': {
         borderColor: theme.palette.primary.main,
      },
   },
}))

const Referrals = () => {
   const theme = useTheme()
   return (
      <Stack sx={{ marginTop: { xs: '30px', md: '0' } }}>
         <Typography variant='h4'>Referrals</Typography>
         <Box sx={{ marginTop: '50px' }}>
            <FormControl variant='standard' sx={{ width: '100%' }}>
               <InputLabel
                  shrink
                  htmlFor='email-input'
                  sx={{ fontSize: '19px', color: '#000' }}>
                  Email :
               </InputLabel>
               <Stack
                  sx={{ gap: '10px' }}
                  direction='row'
                  justifyContent='space-between'>
                  <CustomInput
                     sx={{ flex: '1' }}
                     type='email'
                     id='email-input'
                     //  value={email}
                     //  onChange={e => setEmail(e.target.value)}
                  />
                  <Button disableElevation variant='contained'>
                     Send referral
                  </Button>
               </Stack>
            </FormControl>
            <TableContainer sx={{ marginTop: '20px' }}>
               <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                  <TableHead sx={{ backgroundColor: theme.palette.grey[100] }}>
                     <TableRow>
                        <TableCell
                           sx={{
                              padding: '10px 15px',
                              fontWeight: '600',
                              color: theme.palette.grey[500],
                              fontSize: '16px',
                           }}>
                           Name
                        </TableCell>
                        <TableCell
                           sx={{
                              padding: '10px 15px',
                              fontWeight: '600',
                              color: theme.palette.grey[500],
                              fontSize: '16px',
                           }}
                           align='left'>
                           Account Name
                        </TableCell>
                        <TableCell
                           sx={{
                              padding: '10px 15px',
                              fontWeight: '600',
                              color: theme.palette.grey[500],
                              fontSize: '16px',
                           }}
                           align='left'>
                           Connected On
                        </TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody></TableBody>
               </Table>

               <Stack
                  spacing={1}
                  sx={{ width: '100%', paddingTop: '20px' }}
                  justifyContent='center'
                  alignItems='center'>
                  <Typography variant='body1'>
                     There are no integrations to display.
                  </Typography>
               </Stack>
            </TableContainer>
         </Box>
      </Stack>
   )
}

export default Referrals
