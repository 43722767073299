import { useTheme } from "@emotion/react";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";

const Headers = ({ headers }) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        height: "fit-content",
        borderRight: "1px solid #000",
        width: { xs: "260px", md: "30%" },
      }}
    >
      <Box
        sx={{
          width: "100%",
          minHeight: "60px",
          backgroundColor: theme.palette.grey.main,
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid #000",
          padding: "0 10px",
          position: "sticky",
          top: "0",
          left: "0",
        }}
      >
        <Typography variant="body1">Headers</Typography>
      </Box>
      {headers.map((head, index) => (
        <Box
          key={index}
          sx={{
            width: "100%",
            minHeight: "60px",
            backgroundColor: theme.palette.grey.main,
            borderBottom: "1px solid rgba(0,0,0,0.2)",
            display: "flex",
            alignItems: "center",
            padding: "0 10px",
          }}
        >
          <Typography variant="body1">{head}</Typography>
        </Box>
      ))}
      <Box
        sx={{
          width: "100%",
          minHeight: "60px",
          backgroundColor: theme.palette.grey.main,
          display: "flex",
          alignItems: "center",
          padding: "0 10px",
          position: "sticky",
          top: "0",
          left: "0",
        }}
      ></Box>
    </Stack>
  );
};

export default Headers;
