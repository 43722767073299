import { useTheme } from "@emotion/react";
import { AppBar, Box, Container, Toolbar, Typography } from "@mui/material";
import React from "react";
import logo from "../../Assets/logo.png";

const Navbar = () => {
  const theme = useTheme();
  return (
    <AppBar elevation={0} position="static" sx={{ backgroundColor: theme.palette.grey.main }}>
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "#2C3333",
              textDecoration: "none",
            }}
          >
            <Box sx={{ width: "70px" }} component="img" src={logo} alt="logo" />
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
