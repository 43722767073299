import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import {
   Box,
   Button,
   Divider,
   IconButton,
   Stack,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   TextField,
   Typography,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ModalAddSegment from '../Components/Contact/Segments/ModalAddSegment'
import data from '../Data/data'

const Segments = () => {
   const theme = useTheme()
   const [openAddSegment, setOpenAddSegment] = useState(false)
   return (
      <div>
         <Stack
            direction='row'
            spacing={2}
            justifyContent='space-between'
            sx={{ marginTop: { xs: '30px', md: '0' } }}>
            <Typography variant='h5'>Segments</Typography>
            <Stack
               spacing={1}
               direction='row'
               alignItems='center'
               justifyContent='space-between'>
               <Button
                  onClick={() => setOpenAddSegment(true)}
                  disableElevation
                  disableTouchRipple
                  sx={{
                     fontSize: '14px',
                     textTransform: 'unset',
                     backgroundColor: theme.palette.primary.light,
                     '&:hover': {
                        backgroundColor: theme.palette.primary.light,
                     },
                  }}
                  variant='contained'>
                  Créer un segment
               </Button>
            </Stack>
         </Stack>
         <Divider sx={{ margin: '15px 0 30px' }} />
         <Stack
            spacing={2}
            direction={{ xs: 'column', md: 'row' }}
            justifyContent='space-between'>
            <Typography
               variant='h6'
               sx={{ color: theme.palette.primary.main, fontWeight: 700 }}>
               0 Segments
            </Typography>
            <Box
               sx={{
                  border: `2px ${theme.palette.grey[200]} solid`,
                  display: 'flex',
                  height: 'fit-content',
                  width: { xs: '100%', sm: '60%', md: '350px' },
               }}>
               <TextField
                  size='small'
                  sx={{
                     input: { fontSize: '14px' },
                     flex: 1,
                     '& fieldset': {
                        border: 'none',
                        borderRadius: '0',
                     },
                  }}
                  type='text'
                  label='Recherche un segment'
                  variant='outlined'
               />
               <IconButton
                  sx={{
                     borderRadius: '0',
                     borderLeft: `2px ${theme.palette.grey[200]} solid`,
                  }}>
                  <SearchIcon />
               </IconButton>
            </Box>
         </Stack>

         <TableContainer sx={{ marginTop: '30px' }}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
               <TableHead sx={{ backgroundColor: theme.palette.grey[100] }}>
                  <TableRow>
                     <TableCell
                        sx={{
                           padding: '10px 15px',
                           fontWeight: '600',
                           color: theme.palette.grey[500],
                           fontSize: '16px',
                           width: '50%',
                        }}>
                        Segment
                     </TableCell>
                     <TableCell
                        sx={{
                           padding: '10px 15px',
                           fontWeight: '600',
                           color: theme.palette.grey[500],
                           fontSize: '16px',
                        }}
                        align='right'>
                        ID
                     </TableCell>
                     <TableCell
                        sx={{
                           padding: '10px 15px',
                           fontWeight: '600',
                           color: theme.palette.grey[500],
                           fontSize: '16px',
                        }}
                        align='right'>
                        Catégorie
                     </TableCell>
                     <TableCell
                        sx={{
                           padding: '10px 15px',
                           fontWeight: '600',
                           color: theme.palette.grey[500],
                           fontSize: '16px',
                        }}
                        align='right'>
                        Dernière modification
                     </TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {data.segments.map(segment => (
                     <TableRow
                        key={segment.id}
                        sx={{
                           '&:last-child td, &:last-child th': { border: 0 },
                        }}>
                        <TableCell
                           sx={{ padding: '8px 15px' }}
                           component='th'
                           scope='row'>
                           {segment.name}
                        </TableCell>
                        <TableCell sx={{ padding: '8px 15px' }} align='right'>
                           {segment.id}
                        </TableCell>
                        <TableCell sx={{ padding: '8px 15px' }} align='right'>
                           {segment.category}
                        </TableCell>
                        <TableCell sx={{ padding: '8px 15px' }} align='right'>
                           {segment.lastEdit}
                        </TableCell>
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
            {data.segments.length === 0 && (
               <Stack
                  spacing={1}
                  sx={{ width: '100%', paddingTop: '20px' }}
                  justifyContent='center'
                  alignItems='center'>
                  <Typography variant='h5'>
                     Vous n'avez aucun segment.
                  </Typography>
                  <Typography variant='body1'>
                     Veuillez créer un nouveau segment.
                  </Typography>
               </Stack>
            )}
         </TableContainer>
         <ModalAddSegment
            open={openAddSegment}
            handleClose={() => setOpenAddSegment(false)}
         />
      </div>
   )
}

export default Segments
