import {
   Box,
   Button,
   FormControl,
   InputBase,
   InputLabel,
   MenuItem,
   Select,
   Stack,
   Typography,
   styled,
} from '@mui/material'
import React, { useState } from 'react'
import PhoneInput from '../../Element/phone-input/PhoneInput'
import { useTheme } from '@emotion/react'

const CustomInput = styled(InputBase)(({ theme }) => ({
   'label + &': {
      marginTop: 25,
      width: '100%',
   },
   '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: '#fcfcfb',
      border: '1px solid #ced4da',
      fontSize: 16,
      width: '100%',
      padding: '7.5px 12px',
      transition: theme.transitions.create([
         'border-color',
         'background-color',
         'box-shadow',
      ]),
      '&:focus': {
         borderColor: theme.palette.primary.main,
      },
   },
}))
const TabInformation = () => {
   const theme = useTheme()
   const [firstName, setFirstName] = useState('')
   const [lastName, setLastName] = useState('')
   const [email, setEmail] = useState('')
   const [phone, setPhone] = useState()

   const [company, setCompany] = useState('')
   const [website, setWebsite] = useState('')
   const [address, setAddress] = useState('')
   const [zipCode, setZipCode] = useState('')
   const [city, setCity] = useState('')
   const [region, setRegion] = useState('')
   const [country, setCountry] = useState('')
   return (
      <Stack spacing={4}>
         <Stack direction={{ xs: 'column', md: 'row' }}>
            <Typography
               sx={{ width: { xs: '100%', md: '30%' }, marginBottom: '15px' }}
               variant='h5'>
               Personal Information
            </Typography>
            <Stack spacing={2} sx={{ width: { xs: '100%', md: '50%' } }}>
               <FormControl variant='standard' sx={{ width: '100%' }}>
                  <InputLabel
                     shrink
                     htmlFor='email-input'
                     sx={{ fontSize: '19px', color: '#000' }}>
                     Email :
                  </InputLabel>
                  <CustomInput
                     type='email'
                     id='email-input'
                     value={email}
                     onChange={e => setEmail(e.target.value)}
                  />
               </FormControl>
               <Stack
                  justifyContent='space-between'
                  direction='row'
                  flexWrap='wrap'>
                  <FormControl variant='standard' sx={{ width: '48%' }}>
                     <InputLabel
                        shrink
                        htmlFor='first-input'
                        sx={{ fontSize: '19px', color: '#000' }}>
                        First Name :
                     </InputLabel>
                     <CustomInput
                        type='text'
                        id='first-input'
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)}
                     />
                  </FormControl>
                  <FormControl variant='standard' sx={{ width: '48%' }}>
                     <InputLabel
                        shrink
                        htmlFor='last-input'
                        sx={{ fontSize: '19px', color: '#000' }}>
                        Last Name :
                     </InputLabel>
                     <CustomInput
                        type='text'
                        id='last-input'
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                     />
                  </FormControl>
               </Stack>
               <FormControl variant='standard' sx={{ width: '48%' }}>
                  <InputLabel
                     shrink
                     htmlFor='first-input'
                     sx={{ fontSize: '19px', color: '#000' }}>
                     Phone Number :
                  </InputLabel>
                  <PhoneInput
                     country={'ma'}
                     value={phone}
                     onChange={value => setPhone(value)}
                     styleContainer={{ marginTop: 20 }}
                  />
               </FormControl>
            </Stack>
         </Stack>

         <Stack direction={{ xs: 'column', md: 'row' }}>
            <Typography
               sx={{ width: { xs: '100%', md: '30%' }, marginBottom: '15px' }}
               variant='h5'>
               Company Information
            </Typography>
            <Stack spacing={2} sx={{ width: { xs: '100%', md: '50%' } }}>
               <Stack spacing={2} sx={{ width: '100%' }}>
                  <Typography
                     sx={{
                        fontSize: '19px',
                        color: theme.palette.primary.main,
                        marginTop: '5px',
                     }}>
                     General
                  </Typography>
                  <Stack
                     justifyContent='space-between'
                     direction='row'
                     flexWrap='wrap'>
                     <FormControl variant='standard' sx={{ width: '48%' }}>
                        <InputLabel
                           shrink
                           htmlFor='company-input'
                           sx={{ fontSize: '19px', color: '#000' }}>
                           Company Name :
                        </InputLabel>
                        <CustomInput
                           type='text'
                           id='company-input'
                           value={company}
                           onChange={e => setCompany(e.target.value)}
                        />
                     </FormControl>

                     <FormControl variant='standard' sx={{ width: '48%' }}>
                        <InputLabel
                           shrink
                           htmlFor='website-input'
                           sx={{ fontSize: '19px', color: '#000' }}>
                           Tax ID :
                        </InputLabel>
                        <CustomInput
                           type='text'
                           id='website-input'
                           value={website}
                           onChange={e => setWebsite(e.target.value)}
                        />
                     </FormControl>
                  </Stack>
                  <Box sx={{ width: '48%' }}>
                     <InputLabel
                        shrink
                        htmlFor='address-input'
                        sx={{ fontSize: '19px', color: '#000' }}>
                        Currency :
                     </InputLabel>
                     <Select fullWidth size='small' variant='outlined'>
                        <MenuItem value='USD'>United States Dollar</MenuItem>
                        <MenuItem value='MAD'>Moroccan Dirham</MenuItem>
                        <MenuItem value='EUR'>Euro</MenuItem>
                     </Select>
                  </Box>
               </Stack>

               <Stack spacing={2} sx={{ width: '100%' }}>
                  <Typography
                     sx={{
                        fontSize: '19px',
                        color: theme.palette.primary.main,
                        marginTop: '5px',
                     }}>
                     Billing Information
                  </Typography>
                  <FormControl variant='standard' sx={{ width: '100%' }}>
                     <InputLabel
                        shrink
                        htmlFor='country-input'
                        sx={{ fontSize: '19px', color: '#000' }}>
                        Address :
                     </InputLabel>
                     <CustomInput
                        type='text'
                        id='country-input'
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                     />
                  </FormControl>
                  <Stack spacing={2} direction='row'>
                     <FormControl variant='standard' sx={{ width: '48%' }}>
                        <InputLabel
                           shrink
                           htmlFor='zip-input'
                           sx={{ fontSize: '19px', color: '#000' }}>
                           ZIP Code :
                        </InputLabel>
                        <CustomInput
                           type='text'
                           id='zip-input'
                           value={zipCode}
                           onChange={e => setZipCode(e.target.value)}
                        />
                     </FormControl>

                     <FormControl variant='standard' sx={{ width: '48%' }}>
                        <InputLabel
                           shrink
                           htmlFor='city-input'
                           sx={{ fontSize: '19px', color: '#000' }}>
                           City :
                        </InputLabel>
                        <CustomInput
                           type='text'
                           id='city-input'
                           value={city}
                           onChange={e => setCity(e.target.value)}
                        />
                     </FormControl>
                  </Stack>
                  <Stack spacing={2} direction='row'>
                     <FormControl variant='standard' sx={{ width: '48%' }}>
                        <InputLabel
                           shrink
                           htmlFor='country-input'
                           sx={{ fontSize: '19px', color: '#000' }}>
                           Region :
                        </InputLabel>
                        <CustomInput
                           type='text'
                           id='region-input'
                           value={region}
                           onChange={e => setRegion(e.target.value)}
                        />
                     </FormControl>

                     <FormControl variant='standard' sx={{ width: '48%' }}>
                        <InputLabel
                           shrink
                           htmlFor='country-input'
                           sx={{ fontSize: '19px', color: '#000' }}>
                           Country :
                        </InputLabel>
                        <CustomInput
                           type='text'
                           id='country-input'
                           value={country}
                           onChange={e => setCountry(e.target.value)}
                        />
                     </FormControl>
                  </Stack>
               </Stack>
            </Stack>
         </Stack>

         <Stack
            direction={{ xs: 'column', md: 'row' }}
            sx={{ paddingBottom: '30px' }}
            justifyContent='center'
            alignItems='center'>
            <Typography sx={{ width: { xs: '100%', md: '30%' } }}></Typography>
            <Button
               variant='contained'
               sx={{
                  width: 'fit-content',
                  textTransform: 'capitalize',
                  fontSize: '16px',
               }}>
               Update my profile
            </Button>
         </Stack>
      </Stack>
   )
}

export default TabInformation
