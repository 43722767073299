import styled from '@emotion/styled'
import { Box, Button, Stack, TextField } from '@mui/material'
import React from 'react'

const StyledTextarea = styled(TextField)(({ theme }) => ({
   padding: '5px',
   '& .MuiInputBase-root': {
      height: '250px',
      alignItems: 'flex-start',
   },
   '& label.Mui-focused': {
      color: theme.palette.info.main,
   },
   '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.info.main,
   },
   '& .MuiFormLabel-root ': {
      top: 'unset',
      left: 'unset',
   },
   '& .MuiOutlinedInput-root': {
      '& fieldset': {
         borderColor: theme.palette.grey[400],
      },
      '&:hover fieldset': {
         borderColor: theme.palette.grey[900],
      },
      '&.Mui-focused fieldset': {
         borderColor: theme.palette.info.main,
      },
   },
}))

const WriteFile = ({ emails, onChange, nextHandler }) => {
   return (
      <Stack spacing={2} alignItems='flex-end'>
         <Box
            sx={{
               width: '100%',
            }}>
            <StyledTextarea
               rows={10}
               id='emails-list'
               value={emails}
               onChange={onChange}
               sx={{ width: '100%' }}
               multiline
               placeholder='Enter the emails you want to check separated by comma, e.g : jacobjohnsno@me.com, m.mcormic@gmail.com, ... '
            />
         </Box>
         <Button
            disableElevation
            variant='contained'
            sx={{ textTransform: 'uppercase', width: 'fit-content' }}
            onClick={nextHandler}>
            Next
         </Button>
      </Stack>
   )
}

export default WriteFile
