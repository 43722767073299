import {
   Button,
   Stack,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
} from '@mui/material'
import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import { useTheme } from '@emotion/react'

const Integrations = () => {
   const theme = useTheme()
   return (
      <Stack sx={{ marginTop: { xs: '30px', md: '0' } }}>
         <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'>
            <Typography variant='h4'>Integrations</Typography>
            <Button
               disableElevation
               size='small'
               startIcon={<AddIcon />}
               variant='contained'>
               New
            </Button>
         </Stack>
         <TableContainer sx={{ marginTop: '50px' }}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
               <TableHead sx={{ backgroundColor: theme.palette.grey[100] }}>
                  <TableRow>
                     <TableCell
                        sx={{
                           padding: '10px 15px',
                           fontWeight: '600',
                           color: theme.palette.grey[500],
                           fontSize: '16px',
                        }}>
                        Name
                     </TableCell>
                     <TableCell
                        sx={{
                           padding: '10px 15px',
                           fontWeight: '600',
                           color: theme.palette.grey[500],
                           fontSize: '16px',
                        }}
                        align='left'>
                        Account Name
                     </TableCell>
                     <TableCell
                        sx={{
                           padding: '10px 15px',
                           fontWeight: '600',
                           color: theme.palette.grey[500],
                           fontSize: '16px',
                        }}
                        align='left'>
                        Connected On
                     </TableCell>
                  </TableRow>
               </TableHead>
               <TableBody></TableBody>
            </Table>

            <Stack
               spacing={1}
               sx={{ width: '100%', paddingTop: '20px' }}
               justifyContent='center'
               alignItems='center'>
               <Typography variant='body1'>
                  There are no integrations to display.
               </Typography>
            </Stack>
         </TableContainer>
      </Stack>
   )
}

export default Integrations
