import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useState } from "react";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";

const UploadFile = () => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState({});

  const navigate = useNavigate();

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles);
    console.log(acceptedFiles);

    let fileReader = new FileReader();
    fileReader.onload = function () {
      let parsedJSON = JSON.parse(fileReader.result);
      setData(parsedJSON);
    };
    fileReader.readAsText(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "application/json": [".json"],
    },
  });
  return (
    <Stack sx={{ width: { xs: "90%", sm: "70%", md: "650px" } }} spacing={2} direction="column">
      <Box
        {...getRootProps()}
        sx={{
          width: "100%",
          height: "250px",
          backgroundColor: "#EEEEEE",
          padding: "40px",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography variant="h5" noWrap sx={{ color: "#2C3333", fontWeight: "300" }}>
            Drop the file here...
          </Typography>
        ) : (
          <>
            <DriveFolderUploadIcon sx={{ color: "#2C3333", fontSize: "70px" }} />
            <Typography
              variant="h5"
              noWrap
              sx={{ color: "#2C3333", marginTop: "25px", fontWeight: "300" }}
            >
              Upload Json File
            </Typography>
          </>
        )}
      </Box>
      {file && data && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack>
            <Typography sx={{ fontWeight: "600" }}>{file[0].name}</Typography>
            <Typography sx={{ fontWeight: "600" }}>{Object.keys(data).length} rows</Typography>
          </Stack>
          <Button
            onClick={() =>
              navigate("show-data", {
                state: { data: data, name: file[0].name },
              })
            }
            color="info"
            variant="contained"
            size="small"
          >
            Next
          </Button>
        </Box>
      )}
    </Stack>
  );
};

export default UploadFile;
