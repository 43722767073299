import React from "react";
import App from "./App/App";
import reportWebVitals from "./reportWebVitals";

const Index = () => {
  return <App />;
};
export default Index;

reportWebVitals();
