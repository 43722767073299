import React, { Fragment, useState } from "react";
import {
  AppBar,
  Typography,
  Link,
  Toolbar,
  Stack,
  styled,
  Button,
  Avatar,
  IconButton,
  Popover,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";

import MenuIcon from "@mui/icons-material/Menu";

import Person2Icon from "@mui/icons-material/Person2";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import HelpIcon from "@mui/icons-material/Help";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import data from "../Data/data";

import { Link as routerLink } from "react-router-dom";

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  textTransform: "capitalize",
  padding: "5px 15px",
  fontSize: "17px",
}));

const Navbar = () => {
  const theme = useTheme();
  const [openPopover, setOpenPopover] = useState(false);
  const [anchorPopEl, setAnchorPopEl] = useState(null);
  const [openProfile, setOpenProfile] = useState(false);
  const [anchorProfileEl, setAnchorProfileEl] = useState(null);

  const matchDownMeduim = useMediaQuery(theme.breakpoints.down("md"));
  const matchUpMeduim = useMediaQuery(theme.breakpoints.up("md"));

  // The Menu that shows in meduim to up
  const handleClickProfile = (event) => {
    setOpenProfile(true);
    setAnchorProfileEl(event.currentTarget);
  };
  const handleCloseProfile = () => {
    setOpenProfile(false);
    setAnchorProfileEl(null);
    setOpenPopover(false);
  };
  // List contains the profile, account... (shows in both mobile and large screen)
  const list = (
    <Stack spacing={2}>
      <List>
        <ListItem disablePadding>
          <ListItemButton
            component={routerLink}
            to="profile"
            onClick={handleCloseProfile}
            disableGutters
            sx={{
              padding: "5px 0",
              borderRaduis: "5px",
            }}
          >
            <ListItemIcon
              sx={{
                width: "fit-content",
                minWidth: "unset",
                marginRight: "7px",
                color: theme.palette.primary.dark,
                fontSize: "30px",
              }}
            >
              <Person2Icon fontSize="inherit" />
            </ListItemIcon>
            <ListItemText primary="Profile" sx={{ color: theme.palette.grey[800] }} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            component={routerLink}
            to="profile/integrations"
            onClick={handleCloseProfile}
            disableGutters
            sx={{ padding: "5px 0", borderRaduis: "5px" }}
          >
            <AccountBalanceWalletIcon
              sx={{
                width: "fit-content",
                minWidth: "unset",
                marginRight: "10px",
                color: theme.palette.primary.dark,
                fontSize: "30px",
              }}
            >
              <Person2Icon />
            </AccountBalanceWalletIcon>
            <ListItemText primary="Integrations" sx={{ color: theme.palette.grey[800] }} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            component={routerLink}
            to="profile/billing"
            onClick={handleCloseProfile}
            disableGutters
            sx={{ padding: "5px 0", borderRaduis: "5px" }}
          >
            <CreditCardIcon
              sx={{
                width: "fit-content",
                minWidth: "unset",
                marginRight: "10px",
                color: theme.palette.primary.dark,
                fontSize: "30px",
              }}
            >
              <Person2Icon />
            </CreditCardIcon>
            <ListItemText primary="Billing" sx={{ color: theme.palette.grey[800] }} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            component={routerLink}
            to="profile/referrals"
            onClick={handleCloseProfile}
            disableGutters
            sx={{ padding: "5px 0", borderRaduis: "5px" }}
          >
            <MapsUgcIcon
              sx={{
                width: "fit-content",
                minWidth: "unset",
                marginRight: "10px",
                color: theme.palette.primary.dark,
                fontSize: "30px",
              }}
            >
              <Person2Icon />
            </MapsUgcIcon>
            <ListItemText primary="Referrals" sx={{ color: theme.palette.grey[800] }} />
          </ListItemButton>
        </ListItem>

        {/* <ListItem disablePadding>
               <ListItemButton
                  component={routerLink}
                  to='profile/help'
                  onClick={handleCloseProfile}
                  disableGutters
                  sx={{ padding: '5px 0', borderRaduis: '5px' }}>
                  <HelpIcon
                     sx={{
                        width: 'fit-content',
                        minWidth: 'unset',
                        marginRight: '10px',
                        color: theme.palette.primary.dark,
                        fontSize: '30px',
                     }}>
                     <Person2Icon />
                  </HelpIcon>
                  <ListItemText
                     primary='Help'
                     sx={{ color: theme.palette.grey[800] }}
                  />
               </ListItemButton>
            </ListItem> */}

        <ListItem disablePadding>
          <ListItemButton
            component={routerLink}
            to="login"
            onClick={handleCloseProfile}
            disableGutters
            sx={{
              padding: "5px 0",
              borderRadius: "5px",
            }}
          >
            <ExitToAppIcon
              sx={{
                width: "fit-content",
                minWidth: "unset",
                marginRight: "10px",
                color: theme.palette.primary.dark,
                fontSize: "30px",
              }}
            ></ExitToAppIcon>
            <ListItemText primary="Sign Out" sx={{ color: theme.palette.grey[800] }} />
          </ListItemButton>
        </ListItem>
      </List>
    </Stack>
  );

  // This is the PopOver that shows in the small screen
  // Contains all the information
  const handleClick = (event) => {
    setOpenPopover(true);
    setAnchorPopEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorPopEl(null);
    setOpenPopover(false);
  };
  const menuPopOver = (
    <Popover
      id="navbar-mobile"
      open={openPopover}
      anchorEl={anchorPopEl}
      onClose={handleClose}
      sx={{
        top: "30px",
        "&.MuiPopover-root .MuiPaper-root ": {
          backgroundColor: theme.palette.common.white,
          width: { xs: "90%", sm: "50%" },
          height: "100%",
          padding: "15px",
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Stack spacing={2}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Avatar sx={{ backgroundColor: theme.palette.primary.dark }}>M</Avatar>
          <Typography
            sx={{
              textTransform: "upperCase",
              fontWeight: "600",
              color: theme.palette.grey[800],
            }}
            variant="subtitle1"
          >
            {data.fullName}
          </Typography>
        </Stack>
        <Divider sx={{ margin: "15px 0" }} />
        <Typography
          sx={{
            textTransform: "uppercase",
            color: theme.palette.grey[600],
          }}
          variant="overline"
        >
          solution
        </Typography>
        <Stack spacing={1}>
          <Stack direction="row">
            <StyledButton
              onClick={handleClose}
              component={routerLink}
              to="bulk"
              disableElevation
              disableRipple
              sx={{
                width: "50%",
                backgroundColor: theme.palette.common.white,
                color: theme.palette.primary.main,
                justifyContent: "flex-start",
              }}
            >
              Bulk
            </StyledButton>
            <StyledButton
              onClick={handleClose}
              component={routerLink}
              to="monitor"
              disableElevation
              disableRipple
              sx={{
                width: "50%",
                backgroundColor: theme.palette.common.white,
                color: theme.palette.primary.main,
                justifyContent: "flex-start",
              }}
            >
              Monitor
            </StyledButton>
          </Stack>
          <Stack direction="row">
            <StyledButton
              onClick={handleClose}
              component={routerLink}
              to="single"
              disableElevation
              disableRipple
              sx={{
                width: "50%",
                backgroundColor: theme.palette.common.white,
                color: theme.palette.primary.main,
                justifyContent: "flex-start",
              }}
            >
              Single
            </StyledButton>
            {/* <StyledButton
                     onClick={handleClose}
                     component={routerLink}
                     to='/deliverability'
                     disableElevation
                     disableRipple
                     sx={{
                        width: '50%',
                        backgroundColor: theme.palette.common.white,
                        color: theme.palette.primary.main,
                        justifyContent: 'flex-start',
                     }}>
                     Deliverability
                  </StyledButton> */}

            <StyledButton
              onClick={handleClose}
              component={routerLink}
              to="contacts"
              disableElevation
              disableRipple
              sx={{
                width: "50%",
                backgroundColor: theme.palette.common.white,
                color: theme.palette.primary.main,
                justifyContent: "flex-start",
              }}
            >
              Contact
            </StyledButton>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ margin: "15px 0" }} />
      <Stack spacing={2}>
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="flex-end">
          <Stack>
            <Typography variant="overline" sx={{ color: theme.palette.grey[600] }}>
              credit balance
            </Typography>
            <Typography
              sx={{
                fontSize: "30px",
                fontWeight: "600",
                color: theme.palette.grey[800],
              }}
            >
              {data.credit}
            </Typography>
          </Stack>
          <Button
            size="large"
            disableElevation
            disableTouchRipple
            sx={{
              textTransform: "uppercase",
              fontWeight: "600",
              width: "fit-content",
              height: "fit-content",
              color: theme.palette.primary.dark,
              backgroundColor: "rgba(126, 97, 255, 0.2)",
              "&:hover": {
                backgroundColor: "rgba(126, 97, 255, 0.3)",
              },
            }}
            variant="contained"
          >
            buy credits
          </Button>
        </Stack>
        <Divider sx={{ margin: "15px 0" }} />
        {list}
      </Stack>
    </Popover>
  );

  // This is the items that shows in screen that +meduim
  // Just the items not the logo and the left menu
  const navItems = (
    <Fragment>
      <StyledButton
        onClick={handleClose}
        component={routerLink}
        to="single"
        disableElevation
        disableRipple
      >
        Single
      </StyledButton>

      <StyledButton
        onClick={handleClose}
        component={routerLink}
        to="bulk"
        disableElevation
        disableRipple
      >
        Bulk
      </StyledButton>

      <StyledButton
        onClick={handleClose}
        component={routerLink}
        to="monitor"
        disableElevation
        disableRipple
      >
        Monitor
      </StyledButton>

      {/* <StyledButton onClick={handleClose} component={routerLink} to="deliverability" disableElevation disableRipple>
        Deliverability
      </StyledButton> */}

      <StyledButton
        onClick={handleClose}
        component={routerLink}
        to="contacts"
        disableElevation
        disableRipple
      >
        Contact
      </StyledButton>
    </Fragment>
  );

  // This is the menu that shows in screen that +meduim
  // Just the button and the left menu
  const navbarMenu = (
    <Stack spacing={1} direction="row" alignItems="center">
      <Button
        size="small"
        disableElevation
        disableTouchRipple
        sx={{
          fontSize: "12px",
          textTransform: "uppercase",
          fontWeight: "700",
          padding: "10px",
          width: "fit-content",
          height: "fit-content",
          color: theme.palette.common.white,
          backgroundColor: theme.palette.primary.light,
          "&:hover": {
            backgroundColor: theme.palette.primary.light,
          },
        }}
        variant="contained"
      >
        buy credits
      </Button>
      <Button
        id="profile-popover"
        onClick={handleClickProfile}
        size="small"
        disableElevation
        disableTouchRipple
      >
        <Avatar
          sx={{
            bgcolor: theme.palette.primary.light,
            marginRight: "5px",
            fontSize: "17px",
          }}
        >
          M
        </Avatar>
        <KeyboardArrowDownIcon sx={{ color: "#fff" }} fontSize="medium" />
      </Button>
      <Popover
        aria-describedby="profile-popover"
        open={openProfile}
        anchorEl={anchorProfileEl}
        onClose={handleCloseProfile}
        sx={{
          top: "60px",
          "&.MuiPopover-root .MuiPaper-root ": {
            backgroundColor: theme.palette.common.white,
            padding: "15px",
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Stack>
          <Stack spacing={2} direction="row" alignItems="center" mb={2}>
            <Avatar sx={{ backgroundColor: theme.palette.primary.dark }}>M</Avatar>
            <Typography
              sx={{
                textTransform: "upperCase",
                fontWeight: "600",
                color: theme.palette.grey[800],
              }}
              variant="subtitle1"
            >
              {data.fullName}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="overline" sx={{ color: theme.palette.grey[600] }}>
              credit balance
            </Typography>
            <Typography
              sx={{
                fontSize: "26px",
                fontWeight: "600",
                color: theme.palette.grey[800],
              }}
            >
              {data.credit}
            </Typography>
          </Stack>
          <Divider sx={{ margin: "15px 0" }} />
          {list}
        </Stack>
      </Popover>
    </Stack>
  );

  return (
    <AppBar
      position="static"
      sx={{
        boxShadow: "none",
      }}
    >
      <Toolbar
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 15px",
        }}
      >
        <Stack direction="row" alignItems="center">
          <Link
            href="/"
            underline="none"
            sx={{
              fontSize: "20px",
              textTransform: "uppercase",
              color: theme.palette.common.black,
            }}
          >
            <Avatar
              sx={{
                bgcolor: theme.palette.primary.light,
                marginRight: "5px",
                fontSize: "17px",
                width: "45px",
                height: "45px",
              }}
            >
              LG
            </Avatar>
          </Link>
          {matchUpMeduim && navItems}
        </Stack>
        {matchUpMeduim && navbarMenu}

        {matchDownMeduim && (
          <>
            <IconButton
              onClick={handleClick}
              aria-describedby="navbar-mobile"
              size="large"
              sx={{
                borderRadius: "0",
                margin: 0,
                padding: 0,
                color: theme.palette.common.white,
              }}
              aria-label="menu-icon"
            >
              <MenuIcon fontSize="inherit" />
            </IconButton>
            {menuPopOver}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
