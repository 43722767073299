import { useTheme } from '@emotion/react'
import {
   Accordion,
   AccordionActions,
   AccordionDetails,
   AccordionSummary,
   Avatar,
   Box,
   Button,
   Checkbox,
   Divider,
   FormControlLabel,
   FormGroup,
   InputAdornment,
   List,
   ListItem,
   ListItemAvatar,
   ListItemButton,
   ListItemText,
   MenuItem,
   Modal,
   Paper,
   Popover,
   Select,
   Stack,
   TextField,
   Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import data from '../../../Data/data'
import AddIcon from '@mui/icons-material/Add'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const ModalAddSub = ({ open, handleClose, countries }) => {
   const [folders, setFolders] = useState(data.lists)
   const [lists, setLists] = useState([])
   const [countryFlag, setCountryFlag] = useState('')
   const [countryCode, setCountryCode] = useState('')
   const [filterCountries, setFilterCountries] = useState('')
   const [anchorEl, setAnchorEl] = useState(null) // Popover

   const [createNewList, setCreateNewList] = useState(false)
   const [newListName, setNewListName] = useState('')
   const [newListFolder, setNewListFolder] = useState('')

   const theme = useTheme()

   // Give the default value of flag & code when countries is full
   useEffect(() => {
      if (countries.length !== 0) {
         setCountryFlag(countries[0].flags.png)
         setCountryCode(
            ` ${countries[0].idd.root}${
               countries[0].idd.suffixes ? countries[0].idd.suffixes[0] : ''
            }`
         )
      }
   }, [countries])

   //Fill the list whenever the folders is change
   useEffect(() => {
      let id = 0 // to avoid the repetetion of id in list
      setLists(
         [].concat(
            ...folders.map(folder => {
               return {
                  id: id++,
                  name: folder.name,
                  folder: folder.name,
                  numContacts: folder.numContacts,
                  createAt: folder.createAt,
               }
            })
         )
      )
   }, [folders])

   const handleClickPopover = event => {
      setAnchorEl(event.currentTarget)
   }
   const handleClosePopover = () => {
      setAnchorEl(null)
   }

   const addNewListHandler = () => {
      let currentFolder = folders.filter(
         folder => folder.name === newListFolder
      )[0]
      let id =
         currentFolder.lists.length !== 0
            ? currentFolder.lists.sort((a, b) => b.id - a.id)[0].id + 1
            : 0
      // This above check is if the user just create a folder and want to add a list to it
      // shows error if the lists is empty
      let newList = {
         id: id,
         name: newListName,
         folder: newListFolder,
         numContacts: 0,
         createAt: new Date(Date.now()).toLocaleString().split(',')[0],
      }

      setFolders(
         folders.map(folder => {
            if (folder.name === newListFolder) {
               folder.lists = [...folder.lists, newList]
            }
            return folder
         })
      )
   }

   const popover = (
      <Popover
         id='filter-folder'
         open={Boolean(anchorEl)}
         anchorEl={anchorEl}
         onClose={handleClosePopover}
         anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
         }}>
         <List
            sx={{ width: '200px', height: '240px', padding: '5px' }}
            disablePadding>
            <ListItem disablePadding>
               <TextField
                  value={filterCountries}
                  onChange={event =>
                     setFilterCountries(event.target.value.toLocaleLowerCase())
                  }
                  size='small'
                  variant='outlined'
                  label='Chercher..'
               />
            </ListItem>
            {countries
               .sort((a, b) => {
                  const nameA = a.name.common.toUpperCase() // ignore upper and lowercase
                  const nameB = b.name.common.toUpperCase() // ignore upper and lowercase
                  if (nameA > nameB) {
                     return 1
                  }
                  if (nameA < nameB) {
                     return -1
                  }
                  // names must be equal
                  return 0
               })
               .filter(country =>
                  country.name.common
                     .toLocaleLowerCase()
                     .includes(filterCountries)
               )
               .map(country => (
                  <ListItem key={country.name.common} disablePadding>
                     <ListItemButton
                        disableGutters
                        onClick={() => {
                           handleClosePopover()
                           setCountryFlag(country.flags.png)
                           setCountryCode(
                              ` ${country.idd.root}${
                                 country.idd.suffixes
                                    ? country.idd.suffixes[0]
                                    : ''
                              }`
                           )
                        }}>
                        <ListItemAvatar>
                           <Avatar
                              sx={{ borderRadius: '0', height: '25px' }}
                              alt=''
                              src={country.flags.png}
                           />
                        </ListItemAvatar>
                        <ListItemText
                           primaryTypographyProps={{ fontSize: '13px' }}
                           primary={`${country.name.common} ${
                              country.idd.root
                           }${
                              country.idd.suffixes
                                 ? country.idd.suffixes[0]
                                 : ''
                           }`}
                        />
                     </ListItemButton>
                  </ListItem>
               ))}
         </List>
      </Popover>
   )

   const subInfo = (
      <Paper
         sx={{ width: { xs: '100%', sm: '90%', md: '70%' }, padding: '25px' }}>
         <Typography variant='h6' sx={{ marginBottom: '30px' }}>
            Information d'abonné
         </Typography>
         <Stack spacing={3}>
            <TextField size='small' type='email' label='Email adresse' />
            <TextField size='small' type='text' label='Nom' />
            <TextField size='small' type='text' label='Prénom' />
            <Stack direction='row' spacing={1}>
               <Button
                  onClick={event => {
                     handleClickPopover(event)
                  }}
                  size='small'
                  variant='outlined'
                  endIcon={<ArrowDropDownIcon />}>
                  <Avatar
                     src={countryFlag}
                     sx={{ borderRadius: '0', height: '30px' }}
                  />
               </Button>
               {popover}
               <TextField
                  type='tel'
                  fullWidth
                  size='small'
                  label='Téléphone'
                  InputProps={{
                     startAdornment: (
                        <InputAdornment position='start'>
                           {countryCode}
                        </InputAdornment>
                     ),
                  }}
               />
            </Stack>
         </Stack>
      </Paper>
   )

   const importAccordion = (
      <Accordion
         expanded
         sx={{
            width: { xs: '100%', sm: '90%', md: '70%' },
         }}>
         <AccordionSummary sx={{ backgroundColor: theme.palette.grey[100] }}>
            <Typography variant='h6'>
               Importer dans la liste sélectionnée
            </Typography>
         </AccordionSummary>
         <AccordionDetails>
            <FormGroup>
               {lists.map(list => (
                  <FormControlLabel
                     key={list.id}
                     control={
                        <Checkbox
                           disableTouchRipple
                           disableRipple
                           style={{ color: theme.palette.primary.light }}
                        />
                     }
                     label={
                        <Stack direction='row' spacing={1}>
                           <Typography
                              sx={{
                                 fontSize: '14px',
                                 color: theme.palette.grey[400],
                              }}>
                              [{list.folder}] #{list.id}
                           </Typography>
                           <Typography
                              sx={{
                                 fontSize: '14px',
                                 color: theme.palette.grey[800],
                              }}>
                              {list.name}
                           </Typography>
                        </Stack>
                     }
                  />
               ))}
            </FormGroup>
         </AccordionDetails>
         <AccordionActions>
            {!createNewList && (
               <Button
                  disableElevation
                  disableTouchRipple
                  variant='outlined'
                  size='small'
                  sx={{ fontSize: '12px' }}
                  onClick={() => setCreateNewList(true)}
                  startIcon={<AddIcon />}>
                  Ajouter une nouvelle liste
               </Button>
            )}
            {/*This is show when the user want to create a new list*/}
            {createNewList && (
               <Stack spacing={2} width='100%'>
                  <TextField
                     value={newListName}
                     onChange={event => setNewListName(event.target.value)}
                     variant='outlined'
                     size='small'
                     label='Nom de liste'
                  />
                  <Select
                     size='small'
                     value={newListFolder}
                     onChange={event => setNewListFolder(event.target.value)}>
                     {data.folders.map((folder, index) => (
                        <MenuItem value={folder.name} key={index}>
                           {folder.name}
                        </MenuItem>
                     ))}
                  </Select>
                  <Stack spacing={1} direction='row' justifyContent='flex-end'>
                     <Button
                        disableElevation
                        onClick={() => {
                           setCreateNewList(false)
                           addNewListHandler()
                        }}
                        variant='contained'
                        sx={{
                           backgroundColor: theme.palette.primary.light,
                           width: 'fit-content',
                           '&:hover': {
                              backgroundColor: theme.palette.primary.light,
                           },
                        }}>
                        Créer
                     </Button>
                     <Button
                        disableElevation
                        variant='outlined'
                        onClick={() => setCreateNewList(false)}>
                        Annuler
                     </Button>
                  </Stack>
               </Stack>
            )}
         </AccordionActions>
      </Accordion>
   )

   return (
      <Modal open={open} onClose={handleClose}>
         <Box
            sx={{
               width: '100%',
               height: '100%',
               backgroundColor: '#FFF',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
            }}>
            <Stack
               spacing={2}
               sx={{
                  width: '100%',
                  height: '100%',
                  padding: '20px',
                  overflowY: 'scroll',
               }}>
               <Stack
                  spacing={2}
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'>
                  <Typography variant='h5'>Ajouter un abonné</Typography>
                  <Button
                     onClick={handleClose}
                     variant='outlined'
                     size='medium'>
                     Annuler
                  </Button>
               </Stack>
               <Divider sx={{ margin: '15px 0' }} />
               <Stack spacing={2} alignItems='center'>
                  {subInfo}
                  {importAccordion}
                  <Stack spacing={2} direction='row' justifyContent='flex-end'>
                     <Button
                        disableElevation
                        disableTouchRipple
                        variant='contained'
                        sx={{ backgroundColor: theme.palette.primary.light }}>
                        Sauvegarder
                     </Button>
                  </Stack>
               </Stack>
            </Stack>
         </Box>
      </Modal>
   )
}

export default ModalAddSub
