import { Autocomplete, Box, Button, Stack, TextField } from "@mui/material";
import React, { useState } from "react";

const AddLang = ({ data, onchange, onclick, languages }) => {
  const [lang, setLang] = useState("");
  const [inputLang, setInputLang] = useState("");
  return (
    <Stack sx={{ height: "fit-content", width: { xs: "300px", md: "35%" } }}>
      <Box
        sx={{
          width: { xs: "300px", md: "100%" },
          minHeight: "60px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#fff",
          borderBottom: "1px solid #000",
          padding: "0 10px",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "3",
        }}
      >
        <Autocomplete
          disablePortal
          value={lang || ""}
          onChange={(e, value) => setLang(value)}
          inputValue={inputLang || ""}
          onInputChange={(e, value) => setInputLang(value)}
          options={languages}
          sx={{ width: "100%" }}
          renderInput={(params) => <TextField {...params} label="Select Language" size="small" />}
        />
      </Box>
      {data.map((item, index) => (
        <Box
          id={`box-${index}`}
          key={index}
          sx={{
            width: "100%",
            minHeight: "60px",
            backgroundColor: "#fff",
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid rgba(0,0,0,0.2)",
            justifyContent: "space-between",
            gap: "5px",
            padding: "0 10px",
          }}
        >
          <TextField
            size="small"
            fullWidth
            label={item}
            onBlur={({ target }) => onchange(target.value, item)}
          />
        </Box>
      ))}
      <Box
        sx={{
          width: "100%",
          minHeight: "60px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#fff",
          padding: "0 10px",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "3",
        }}
      >
        <Button component="a" onClick={onclick} disableElevation size="small" variant="contained">
          Download
        </Button>
      </Box>
    </Stack>
  );
};

export default AddLang;
