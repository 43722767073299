import { useTheme } from '@emotion/react'
import {
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   Divider,
   FormControl,
   FormHelperText,
   IconButton,
   MenuItem,
   Select,
   TextField,
   Typography,
} from '@mui/material'
import { Stack } from '@mui/system'
import React, { useState } from 'react'
import ModalAddList from '../Components/Contact/List/ModalAddList'

import DeleteIcon from '@mui/icons-material/Delete'
import ModeIcon from '@mui/icons-material/Mode'
import SearchIcon from '@mui/icons-material/Search'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import data from '../Data/data'
import CustomTable from '../Components/CustomTable/CustomTable'
import ModalAddSub from '../Components/Contact/List/ModalAddSub'
import ModalImport from '../Components/Contact/List/ModalImport'
import ModalListSettings from '../Components/Contact/List/ModalListSettings'

const List = ({ countriesData }) => {
   const [selectedFolderId, setSelectedFolderId] = useState(0)
   const [openAddList, setOpenAddList] = useState(false)
   const [openListSettings, setOpenListSettings] = useState(false)
   const [openImportFile, setOpenImportFile] = useState(false)
   const [openDialogDuplicate, setOpenDialogDuplicate] = useState(false)
   const [openDialogDelete, setOpenDialogDelete] = useState(false)
   const [openAddSub, setOpenAddSub] = useState(false)

   const theme = useTheme()

   // Put the selected folder from the select to the state
   const handleChange = event => {
      setSelectedFolderId(event.target.value)
   }

   const handleDialogDuplicateClose = () => {
      setOpenDialogDuplicate(false)
   }

   const handleDialogDeleteClose = () => {
      setOpenDialogDelete(false)
   }

   // Dialog of duplicate list
   const duplicateDialog = (
      <Dialog onClose={handleDialogDuplicateClose} open={openDialogDuplicate}>
         <DialogTitle
            sx={{
               display: 'flex',
               justifyContent: 'flex-start',
               alignItems: 'center',
            }}>
            <ContentCopyIcon sx={{ marginRight: '10px' }} /> Dupliquer
         </DialogTitle>
         <DialogContent>
            <DialogContentText id='description'>
               Voulez-vous vraiment dupliquer cette liste ?
            </DialogContentText>
         </DialogContent>
         <DialogActions>
            <DialogActions>
               <Button
                  disableElevation
                  disableTouchRipple
                  variant='contained'
                  sx={{ backgroundColor: theme.palette.primary.light }}
                  onClick={handleDialogDuplicateClose}>
                  Ok
               </Button>
               <Button
                  disableElevation
                  disableTouchRipple
                  variant='outlined'
                  onClick={handleDialogDuplicateClose}>
                  Annuler
               </Button>
            </DialogActions>
         </DialogActions>
      </Dialog>
   )

   // Dialog of delete list
   const deleteDialog = (
      <Dialog onClose={handleDialogDeleteClose} open={openDialogDelete}>
         <DialogTitle
            sx={{
               display: 'flex',
               justifyContent: 'flex-start',
               alignItems: 'center',
            }}>
            <DeleteIcon sx={{ marginRight: '10px' }} /> Supprimer
         </DialogTitle>
         <DialogContent>
            <DialogContentText id='description'>
               <Typography
                  variant='subtitle1'
                  component='span'
                  sx={{
                     color: theme.palette.grey[800],
                     marginBottom: '10px',
                     display: 'block',
                  }}>
                  Voulez-vous vraiment supprimer cette liste?
               </Typography>
               <Typography variant='subtitle2' component='span'>
                  Veuillez noter qu'en supprimant une liste, vous ne supprimez
                  pas les contacts de la liste.
               </Typography>
            </DialogContentText>
         </DialogContent>
         <DialogActions>
            <DialogActions>
               <Button
                  disableElevation
                  disableTouchRipple
                  variant='contained'
                  sx={{ backgroundColor: theme.palette.primary.light }}
                  onClick={handleDialogDeleteClose}>
                  Ok
               </Button>
               <Button
                  disableElevation
                  disableTouchRipple
                  variant='outlined'
                  onClick={handleDialogDeleteClose}>
                  Annuler
               </Button>
            </DialogActions>
         </DialogActions>
      </Dialog>
   )

   return (
      <div>
         <Stack
            direction='row'
            spacing={2}
            justifyContent='space-between'
            sx={{ marginTop: { xs: '30px', md: '0' } }}>
            <Typography variant='h5'>Lists</Typography>
            <Stack
               spacing={1}
               direction='row'
               alignItems='center'
               justifyContent='space-between'>
               <Button
                  onClick={() => setOpenAddList(true)}
                  disableElevation
                  disableTouchRipple
                  sx={{
                     fontSize: '14px',
                     textTransform: 'unset',
                     backgroundColor: theme.palette.primary.light,
                     '&:hover': {
                        backgroundColor: theme.palette.primary.light,
                     },
                  }}
                  variant='contained'>
                  Ajouter une nouvelle liste
               </Button>
            </Stack>
         </Stack>
         <Divider sx={{ margin: '15px 0 30px' }} />
         <Stack
            spacing={2}
            direction={{ xs: 'column', md: 'row' }}
            justifyContent='space-between'>
            <FormControl
               sx={{
                  width: { xs: '100%', sm: '60%', md: '400px' },
               }}>
               <Box
                  sx={{
                     border: `2px ${theme.palette.grey[200]} solid`,
                     width: '100%',
                     display: 'flex',
                  }}>
                  <Select
                     size='small'
                     id='folder-select'
                     value={selectedFolderId}
                     onChange={handleChange}
                     sx={{ '& > fieldset': { border: 'none' }, flex: 1 }}>
                     {data.folders.map(folder => (
                        <MenuItem key={folder.id} value={folder.id}>
                           {folder.name}
                        </MenuItem>
                     ))}
                  </Select>
                  {/* <IconButton
              sx={{
                borderRadius: "0",
                borderLeft: `2px ${theme.palette.grey[200]} solid`,
              }}
            >
              <ModeIcon />
            </IconButton>
            <IconButton
              sx={{
                borderRadius: "0",
                borderLeft: `2px ${theme.palette.grey[200]} solid`,
              }}
            >
              <DeleteIcon />
            </IconButton> */}
               </Box>
               <FormHelperText sx={{ fontSize: '15px' }}>
                  You have currently {data.folders.length} folder(s) and{' '}
                  {data.lists.length} list(s)
               </FormHelperText>
            </FormControl>

            <Box
               sx={{
                  border: `2px ${theme.palette.grey[200]} solid`,
                  display: 'flex',
                  height: 'fit-content',
                  width: { xs: '100%', sm: '60%', md: '300px' },
               }}>
               <TextField
                  size='small'
                  sx={{
                     input: { fontSize: '14px' },
                     flex: 1,
                     '& fieldset': {
                        border: 'none',
                        borderRadius: '0',
                     },
                  }}
                  type='text'
                  label='Recherche une liste'
                  variant='outlined'
               />
               <IconButton
                  sx={{
                     borderRadius: '0',
                     borderLeft: `2px ${theme.palette.grey[200]} solid`,
                  }}>
                  <SearchIcon />
               </IconButton>
            </Box>
         </Stack>

         <CustomTable
            headers={['id', 'nom', 'nombre de contacts', 'actions']}
            data={data.lists}
            handelDeleteModal={() => setOpenDialogDelete(true)}
            handelAddSubModal={() => setOpenAddSub(true)}
            handleDuplicateModal={() => setOpenDialogDuplicate(true)}
            handleListSettingsModal={() => setOpenListSettings(true)}
            handleImportFile={() => setOpenImportFile(true)}
         />

         {duplicateDialog}
         {deleteDialog}
         <ModalAddList
            open={openAddList}
            handleClose={() => setOpenAddList(false)}
         />
         <ModalListSettings
            open={openListSettings}
            handleClose={() => setOpenListSettings(false)}
         />
         <ModalAddSub
            countries={countriesData}
            open={openAddSub}
            handleClose={() => setOpenAddSub(false)}
         />
         <ModalImport
            open={openImportFile}
            handleClose={() => setOpenImportFile(false)}
         />
      </div>
   )
}

export default List
