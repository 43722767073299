import { useTheme } from '@emotion/react'
import {
   Alert,
   Avatar,
   Box,
   Breadcrumbs,
   Button,
   Checkbox,
   Divider,
   FormControlLabel,
   FormGroup,
   IconButton,
   Link,
   List,
   ListItem,
   ListItemText,
   MenuItem,
   Modal,
   Popover,
   Select,
   Stack,
   Switch,
   Table,
   TableBody,
   TableCell,
   TableHead,
   TableRow,
   TextField,
   Typography,
} from '@mui/material'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import readXlsxFile from 'read-excel-file'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import SearchIcon from '@mui/icons-material/Search'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { DataGrid } from '@mui/x-data-grid'
import styled from '@emotion/styled'
import data from '../../../Data/data'

const StyledSwitch = styled(props => (
   <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
   width: 40,
   height: 24,
   padding: 0,
   marginRight: '5px',
   '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
         transform: 'translateX(16px)',
         color: '#fff',
         '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.primary.light,
            opacity: 1,
            border: 0,
         },
         '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
         },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
         color: '#33cf4d',
         border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
         color:
            theme.palette.mode === 'light'
               ? theme.palette.grey[100]
               : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
         opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
   },
   '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 20,
      height: 20,
   },
   '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.grey[200],
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
         duration: 500,
      }),
   },
}))

const ModalImport = ({ open, handleClose }) => {
   const [folders, setFolders] = useState(data.folders)
   const [lists, setLists] = useState(data.lists)

   const [file, setFile] = useState()
   const [rows, setRows] = useState([])
   const [headers, setHeaders] = useState([])
   const [confirmFile, setConfirmFile] = useState(false)

   const [mappedItems, setMappedItems] = useState([])
   const [confirmMapping, setConfirmMapping] = useState(false)
   const [contactAttr, setContactAttr] = useState([
      { name: 'email', type: 'email' },
      { name: 'contact id', type: 'chifre' },
      { name: 'nom', type: 'texte' },
      { name: 'prénom', type: 'texte' },
      { name: 'sms', type: 'sms' },
   ])
   // States to handle the change in type select & textField on create new attr
   const [newAttrName, setNewAttrName] = useState('')
   const [newAttrType, setNewAttrType] = useState('')
   const [openCreate, setOpenCreate] = useState([]) // handle show&hide of the create form

   const [selecedList, setSelectedList] = useState([])
   const [confirmList, setconfirmList] = useState(false)
   const [showAddList, setShowAddList] = useState(false)
   const [newListName, setNewListName] = useState('')
   const [newListFolder, setNewListFolder] = useState('')
   const [anchorEl, setAnchorEl] = useState(null) // Popover
   // Check folder filter
   // Map over the lists and define default value as false

   const [filterFolder, setFilterFolder] = useState([])
   const [openCreateFolder, setOpenCreateFolder] = useState(false)
   const [newFolderName, setNewFolderName] = useState('')

   // opt-in agrement state
   const [confirm, setConfirm] = useState(false)
   // Finalize import States
   const [finalizeSwitch, setFinalizeSwitch] = useState({
      updateAttr: true,
      importEmpty: false,
      blockImportedEmails: false,
      blockImportedSms: false,
   })

   // handle change of the finalize checkboxs
   const handleChange = event => {
      setFinalizeSwitch({
         ...finalizeSwitch,
         [event.target.name]: event.target.checked,
      })
   }

   // Handle Click & close of filter folders popover
   const handleClickPopover = event => {
      setAnchorEl(event.currentTarget)
   }
   const handleClosePopover = () => {
      setAnchorEl(null)
   }

   const addNewAttrHandler = headerName => {
      setContactAttr([...contactAttr, { name: newAttrName, type: newAttrType }])
      setOpenCreate(
         openCreate.map(item => {
            if (item.name === headerName) item.open = false
            return item
         })
      )
      setNewAttrName('')
      setNewAttrType('')
   }

   const addNewListHandler = () => {
      let currentFolder = lists.filter(
         folder => folder.name === newListFolder
      )[0]
      let id =
         currentFolder.lists.length !== 0
            ? currentFolder.lists.sort((a, b) => b.id - a.id)[0].id + 1
            : 0
      // This above check is if the user just create a folder and want to add a list to it
      // shows error if the lists is empty
      let newList = {
         id: id,
         name: newListName,
         folder: newListFolder,
         numContacts: 0,
         createAt: new Date(Date.now()).toLocaleString().split(',')[0],
      }

      setLists(
         lists.map(folder => {
            if (folder.name === newListFolder) {
               folder.lists = [...folder.lists, newList]
            }
            return folder
         })
      )
   }

   // Dummy data to show in the table
   const dataColumns = [
      { field: 'id', headerName: 'ID', width: 100 },
      { field: 'name', headerName: 'Nom de liste', width: 200 },
      { field: 'folder', headerName: 'Dossier', width: 200 },
      { field: 'numContacts', headerName: 'No de contacts', width: 150 },
      { field: 'createAt', headerName: 'Créé à', width: 150 },
   ]

   // Get the headers on the header state and create an array of the mapped elements :
   // [{header : headOne, mapping : false/email/contactId/nom/prenom/sms/ NEW CREATED BY THE USER}]
   // This function is called in onclick of the confirm file button
   const itemsMap = () => {
      setMappedItems(() => {
         return headers.map(header => ({ head: header, mapping: false }))
      })
   }

   const createAttrsMap = () => {
      setOpenCreate(() => {
         return headers.map(header => ({ name: header, open: false }))
      })
   }

   const theme = useTheme()

   const onDrop = useCallback(acceptedFiles => {
      setFile(acceptedFiles)
      readXlsxFile(acceptedFiles[0]).then(rows => {
         setRows(rows)
         setHeaders(rows[0])
      })
   }, [])

   const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: {
         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
            '.xlsx',
         ],
         'application/vnd.ms-excel': ['.xls'],
      },
   })

   // This is the part of uploading file
   // there are some elements that hide&show depend on the state of file state
   const uploadFile = (
      <Stack
         sx={{
            width: { xs: '100%', sm: '90%', md: '70%' },
            padding: '30px 0',
            borderBottom: `2px ${theme.palette.grey[300]} solid`,
         }}>
         <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'>
            <Box>
               <Typography
                  variant='h6'
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     fontSize: '18px',
                  }}
                  gutterBottom>
                  {confirmFile ? (
                     <CheckCircleIcon
                        sx={{ color: theme.palette.success.light }}
                     />
                  ) : (
                     <Avatar
                        variant='circular'
                        sx={{
                           border: '2px black solid',
                           width: '23px',
                           height: '23px',
                           fontSize: '14px',
                           fontWeight: '600',
                           color: '#000',
                           backgroundColor: 'transparent',
                           marginRight: '10px',
                        }}>
                        1
                     </Avatar>
                  )}
                  Téléchargez votre fichier
               </Typography>
               {confirmFile && (
                  <Stack
                     direction='row'
                     spacing={1}
                     sx={{ marginLeft: '25px' }}>
                     <Typography
                        sx={{
                           color: theme.palette.grey[600],
                           fontSize: '15px',
                        }}>
                        {file[0].name}
                     </Typography>
                     <Typography
                        sx={{
                           color: theme.palette.grey[600],
                           fontSize: '15px',
                        }}>
                        Prévisualisez les 10 premières lignes de votre fichier
                     </Typography>
                  </Stack>
               )}
               {!confirmFile && (
                  <Typography
                     sx={{
                        color: theme.palette.grey[600],
                        fontSize: '15px',
                        marginLeft: '30px',
                     }}>
                     Sélectionnez un fichier contenant vos contacts à importer.
                  </Typography>
               )}
            </Box>
            {confirmFile && (
               <Link
                  onClick={() => setConfirmFile(false)}
                  sx={{
                     color: theme.palette.primary.light,
                     fontWeight: '500',
                     fontSize: '16px',
                     cursor: 'pointer',
                  }}
                  underline='none'>
                  Modifier
               </Link>
            )}
         </Stack>

         {!confirmFile && (
            <Fragment>
               {!file && (
                  <Stack
                     {...getRootProps({ className: 'dropzone' })}
                     sx={{
                        backgroundColor: theme.palette.grey[100],
                        borderRadius: '10px',
                        height: '280px',
                        cursor: 'pointer',
                        width: '100%',
                        margin: '10px 0',
                        textAlign: 'center',
                     }}
                     justifyContent='center'
                     alignItems='center'>
                     <input {...getInputProps()} />
                     <CloudDownloadIcon
                        sx={{
                           width: '120px',
                           height: '120px',
                           color: theme.palette.grey[500],
                        }}
                     />
                     <Typography
                        sx={{
                           color: theme.palette.grey[700],
                           fontSize: '18px',
                        }}>
                        sélectionnez votre fichier ou glissez-déposez-le ici
                     </Typography>
                  </Stack>
               )}
               {file && (
                  <Fragment>
                     <Alert
                        severity='success'
                        sx={{
                           border: `1px ${theme.palette.success.dark} solid`,
                           padding: '10px 20px',
                           fontSize: '16px',
                           cursor: 'pointer',
                           margin: '10px 0',
                           display: 'flex',
                           alignItems: 'center',
                        }}
                        action={
                           <Link
                              color='inherit'
                              size='small'
                              underline='none'
                              onClick={() => setFile()}
                              sx={{ fontSize: '14px' }}>
                              Annuler
                           </Link>
                        }>
                        Votre fichier a été téléchargé!
                        <Typography sx={{ marginTop: '10px' }}>
                           {file[0].name}
                        </Typography>
                     </Alert>
                     <Typography
                        sx={{
                           color: theme.palette.grey[600],
                           fontSize: '15px',
                        }}>
                        Prévisualisez les 10 premières lignes de votre fichier
                     </Typography>
                     <Table>
                        <TableHead
                           sx={{ backgroundColor: theme.palette.grey[100] }}>
                           <TableRow>
                              {headers.map((head, index) => (
                                 <TableCell
                                    key={index}
                                    align='left'
                                    sx={{
                                       padding: '7px',
                                       fontSize: '16px',
                                       border: `1px ${theme.palette.grey[300]} solid`,
                                    }}>
                                    {head}
                                 </TableCell>
                              ))}
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {rows.slice(1, 11).map((row, index) => (
                              <TableRow key={index}>
                                 {row.map((row, index) => (
                                    <TableCell
                                       key={index}
                                       sx={{
                                          padding: '7px',
                                          fontSize: '14px',
                                          border: `1px ${theme.palette.grey[300]} solid`,
                                       }}>
                                       {row}
                                    </TableCell>
                                 ))}
                              </TableRow>
                           ))}
                           <TableRow>
                              {headers.map((haed, index) => (
                                 <TableCell
                                    key={index}
                                    sx={{
                                       padding: '7px',
                                       fontSize: '14px',
                                       border: `1px ${theme.palette.grey[300]} solid`,
                                    }}>
                                    ...
                                 </TableCell>
                              ))}
                           </TableRow>
                        </TableBody>
                     </Table>
                     <Button
                        disableElevation
                        disableTouchRipple
                        variant='contained'
                        sx={{
                           margin: '10px auto',
                           fontSize: '12px',
                           backgroundColor: theme.palette.primary.light,
                           width: 'fit-content',
                        }}
                        onClick={() => {
                           setConfirmFile(true)
                           itemsMap()
                           createAttrsMap()
                        }}>
                        Confirmer votre fichier
                     </Button>
                  </Fragment>
               )}
            </Fragment>
         )}
      </Stack>
   )

   // This is the part of mapping file headers
   // there are some elements that hide&show depend on the state of file and mapping state
   const mappingFile = (
      <Stack
         sx={{
            width: { xs: '100%', sm: '90%', md: '70%' },
            padding: '30px 0',
            borderBottom: `2px ${theme.palette.grey[300]} solid`,
         }}>
         {/*The header of mapping*/}
         <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'>
            <Box>
               <Typography
                  variant='h6'
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     fontSize: '18px',
                     margin: '0',
                  }}
                  gutterBottom>
                  {confirmMapping ? (
                     <CheckCircleIcon
                        sx={{ color: theme.palette.success.light }}
                     />
                  ) : (
                     <Avatar
                        variant='circular'
                        sx={{
                           border: '2px black solid',
                           width: '23px',
                           height: '23px',
                           fontSize: '14px',
                           fontWeight: '600',
                           color: '#000',
                           backgroundColor: 'transparent',
                           marginRight: '10px',
                        }}>
                        2
                     </Avatar>
                  )}
                  Mapping data
               </Typography>
               {confirmMapping && (
                  <Stack
                     direction='column'
                     spacing={2}
                     sx={{ marginLeft: '25px' }}>
                     <Typography
                        sx={{
                           color: theme.palette.grey[600],
                           fontSize: '15px',
                        }}>
                        {
                           mappedItems.filter(item => item.mapping !== false)
                              .length
                        }{' '}
                        attribut
                     </Typography>
                  </Stack>
               )}
               {confirmFile && !confirmMapping && (
                  <Typography
                     sx={{
                        color: theme.palette.grey[600],
                        fontSize: '15px',
                        marginLeft: '30px',
                     }}>
                     Veuillez sélectionner les attributs qui correspondent à vos
                     données. Vous pouvez sélectionner des attributs existants
                     ou en créer de nouveaux.
                     <br /> Le paramètre par défaut n'importe pas les données.
                  </Typography>
               )}
            </Box>
            {confirmMapping && (
               <Link
                  onClick={() => setConfirmMapping(false)}
                  sx={{
                     color: theme.palette.primary.light,
                     fontWeight: '500',
                     fontSize: '16px',
                     cursor: 'pointer',
                  }}
                  underline='none'>
                  Modifier
               </Link>
            )}
         </Stack>

         {confirmFile && !confirmMapping && (
            <Fragment>
               {/*The Header of the table*/}
               <Stack
                  spacing={1}
                  direction={{ xs: 'column', sm: 'row' }}
                  justifyContent='space-between'
                  sx={{ marginTop: '25px' }}>
                  <Typography
                     sx={{
                        color: theme.palette.grey[600],
                        fontSize: '16px',
                        flex: '1',
                     }}
                     variant='h6'>
                     EN-TETE DE FICHIER
                  </Typography>
                  <Typography
                     sx={{
                        color: theme.palette.grey[600],
                        fontSize: '16px',
                        flex: '1',
                     }}
                     variant='h6'>
                     DATA
                  </Typography>
                  <Typography
                     sx={{
                        color: theme.palette.grey[600],
                        fontSize: '16px',
                        flex: '1',
                     }}
                     variant='h6'>
                     ATTRIBUT DE CONTACT
                  </Typography>
               </Stack>

               {/*Mapping in all the headers and show the box*/}
               <Stack spacing={2}>
                  {headers.map((header, headerIndex) => (
                     <Stack
                        key={headerIndex}
                        spacing={1}
                        direction={{ xs: 'column', sm: 'row' }}
                        justifyContent='space-between'
                        alignItems={{ xs: 'flex-start', md: 'center' }}
                        sx={{
                           backgroundColor: theme.palette.grey[100],
                           padding: '15px',
                        }}>
                        <Typography
                           sx={{
                              flex: '1',
                              fontSize: '18px',
                              fontWeight: '500',
                           }}
                           variant='h6'>
                           {header}
                        </Typography>
                        <Stack sx={{ flex: '1' }}>
                           {rows.slice(1, 4).map((row, rowIndex) => (
                              <Typography
                                 sx={{ fontSize: '14px' }}
                                 key={rowIndex}
                                 variant='h6'>
                                 {row[headerIndex]}
                              </Typography>
                           ))}
                        </Stack>
                        {!openCreate.filter(item => item.name === header)[0]
                           .open && (
                           <Select
                              sx={{
                                 flex: '1',
                                 backgroundColor: theme.palette.grey[50],
                              }}
                              size='small'
                              value={mappedItems[headerIndex].mapping || false}
                              onChange={event =>
                                 setMappedItems([
                                    ...mappedItems.map(item => {
                                       if (item.head === header) {
                                          return {
                                             head: header,
                                             mapping: event.target.value,
                                          }
                                       }
                                       return item
                                    }),
                                 ])
                              }>
                              <MenuItem value={false}>Ne pas importer</MenuItem>
                              <Typography
                                 sx={{
                                    fontSize: '13px',
                                    fontWeight: '700',
                                    padding: '5px 10px',
                                    backgroundColor: theme.palette.grey[200],
                                 }}>
                                 Attributs des contacts
                              </Typography>
                              <MenuItem>
                                 <Link
                                    underline='none'
                                    sx={{
                                       color: theme.palette.primary.light,
                                       fontSize: '15px',
                                    }}
                                    onClick={() => {
                                       setOpenCreate(
                                          openCreate.map(item => {
                                             if (item.name === header)
                                                item.open = true
                                             return item
                                          })
                                       )
                                    }}>
                                    Ajouter un nouvel attribut
                                 </Link>
                              </MenuItem>
                              {contactAttr.map(attr => (
                                 <MenuItem value={attr.type} key={attr.name}>
                                    <Typography variant='button'>
                                       {attr.name}
                                    </Typography>
                                    <Typography
                                       variant='caption'
                                       sx={{
                                          lineHeight: '0',
                                          margin: '5px 0 0 5px',
                                          color: theme.palette.grey[600],
                                       }}>
                                       {attr.type}
                                    </Typography>
                                 </MenuItem>
                              ))}
                           </Select>
                        )}

                        {/* This shows if the user want to create another attr*/}
                        {openCreate.filter(item => item.name === header)[0]
                           .open && (
                           <Stack
                              spacing={2}
                              direction='row'
                              alignItems='center'>
                              <Stack spacing={1}>
                                 <Select
                                    value={newAttrType}
                                    sx={{
                                       backgroundColor: theme.palette.grey[50],
                                    }}
                                    size='small'
                                    onChange={event =>
                                       setNewAttrType(event.target.value)
                                    }>
                                    {[
                                       'Texte',
                                       'Chiffre',
                                       'Date',
                                       'Boolean',
                                       'Catégorie',
                                    ].map((item, index) => (
                                       <MenuItem key={index} value={item}>
                                          {item}
                                       </MenuItem>
                                    ))}
                                 </Select>
                                 <TextField
                                    size='small'
                                    id='attr-name'
                                    label='Entrer le nom'
                                    variant='outlined'
                                    value={newAttrName}
                                    onChange={event =>
                                       setNewAttrName(event.target.value)
                                    }
                                    sx={{
                                       backgroundColor: theme.palette.grey[50],
                                    }}
                                 />
                              </Stack>
                              <Stack spacing={1}>
                                 <Button
                                    size='small'
                                    disableElevation
                                    variant='contained'
                                    sx={{
                                       backgroundColor:
                                          theme.palette.primary.light,
                                    }}
                                    onClick={() => addNewAttrHandler(header)}>
                                    Créer
                                 </Button>
                                 <Button
                                    size='small'
                                    disableElevation
                                    variant='outlined'
                                    onClick={() => {
                                       setOpenCreate(
                                          openCreate.map(item => {
                                             if (item.name === header)
                                                item.open = false
                                             return item
                                          })
                                       )
                                    }}>
                                    Annuler
                                 </Button>
                              </Stack>
                           </Stack>
                        )}
                     </Stack>
                  ))}
               </Stack>

               <Stack
                  spacing={3}
                  sx={{ marginTop: '15px' }}
                  alignItems='center'
                  justifyContent='center'
                  direction={{ xs: 'column', sm: 'row' }}>
                  <Stack spacing={1}>
                     <Typography>
                        <Typography
                           component='span'
                           sx={{
                              color: theme.palette.primary.light,
                              marginRight: '5px',
                              fontWeight: '600',
                           }}>
                           {
                              mappedItems.filter(item => item.mapping !== false)
                                 .length
                           }{' '}
                           colonne
                        </Typography>
                        à importer
                     </Typography>
                     <Typography>
                        <Typography
                           component='span'
                           sx={{
                              color: theme.palette.grey[600],
                              marginRight: '5px',
                              fontWeight: '600',
                           }}>
                           {
                              mappedItems.filter(item => item.mapping === false)
                                 .length
                           }{' '}
                           colonne
                        </Typography>
                        à ignorée
                     </Typography>
                  </Stack>
                  <Button
                     size='small'
                     variant='contained'
                     sx={{
                        backgroundColor: theme.palette.primary.light,
                        width: 'fit-content',
                     }}
                     onClick={() => setConfirmMapping(true)}>
                     Confirmer mapping
                  </Button>
               </Stack>
            </Fragment>
         )}
      </Stack>
   )

   const filterFolderPopover = (
      <Popover
         id='filter-folder'
         open={Boolean(anchorEl)}
         anchorEl={anchorEl}
         onClose={handleClosePopover}
         anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
         }}>
         <Stack spacing={2} sx={{ padding: '10px 20px' }}>
            <Breadcrumbs aria-label='filter link'>
               <Link
                  sx={{ cursor: 'pointer', color: theme.palette.primary.light }}
                  underline='none'
                  onClick={() => {
                     setFilterFolder([
                        ...filterFolder.map(item => {
                           item.checked = true
                           return item
                        }),
                     ])
                  }}>
                  Tout sélectionner
               </Link>
               <Link
                  sx={{ cursor: 'pointer', color: theme.palette.primary.light }}
                  underline='none'
                  onClick={() => {
                     setFilterFolder([
                        ...filterFolder.map(item => {
                           item.checked = false
                           return item
                        }),
                     ])
                  }}>
                  Clair
               </Link>
            </Breadcrumbs>
            <FormGroup>
               {folders.map((folder, index) => (
                  <FormControlLabel
                     key={index}
                     control={
                        <Checkbox
                           name={folder.name}
                           checked={
                              filterFolder[index]
                                 ? filterFolder[index].checked
                                 : false
                           }
                           onChange={event => {
                              setFilterFolder([
                                 ...filterFolder.map(item => {
                                    if (item.name === folder.name) {
                                       item.checked = event.target.checked
                                    }
                                    return item
                                 }),
                              ])
                           }}
                        />
                     }
                     label={folder.name}
                  />
               ))}
            </FormGroup>
            <Stack spacing={1} direction='row'>
               <Button
                  disableElevation
                  size='small'
                  variant='contained'
                  sx={{ backgroundColor: theme.palette.primary.light }}
                  onClick={handleClosePopover}>
                  Appliquer
               </Button>
               <Button
                  disableElevation
                  size='small'
                  variant='outlined'
                  onClick={handleClosePopover}>
                  Annuler
               </Button>
            </Stack>
         </Stack>
      </Popover>
   )

   // This is the part of choosing lists
   // there are some elements that hide&show depend on the state of list state
   const selectList = (
      <Stack
         sx={{
            width: { xs: '100%', sm: '90%', md: '70%' },
            padding: '30px 0',
         }}>
         {/*The header of mapping*/}
         <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'>
            <Box>
               <Typography
                  variant='h6'
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     fontSize: '18px',
                  }}
                  gutterBottom>
                  {confirmList ? (
                     <CheckCircleIcon
                        sx={{ color: theme.palette.success.light }}
                     />
                  ) : (
                     <Avatar
                        variant='circular'
                        sx={{
                           border: '2px black solid',
                           width: '23px',
                           height: '23px',
                           fontSize: '14px',
                           fontWeight: '600',
                           color: '#000',
                           backgroundColor: 'transparent',
                           margin: '0 10px 0 5px',
                        }}>
                        3
                     </Avatar>
                  )}
                  Sélectionnez une liste
               </Typography>
               {confirmList && (
                  <Stack
                     spacing={1}
                     direction='row'
                     sx={{ marginLeft: '25px' }}
                     flexWrap='wrap'>
                     {selecedList.map(list => (
                        <Typography
                           key={list}
                           sx={{
                              color: theme.palette.grey[600],
                              fontSize: '15px',
                           }}>
                           {`${lists[list].name} (${lists[list].folder})`},
                        </Typography>
                     ))}
                  </Stack>
               )}
               {confirmMapping && !confirmList && (
                  <Stack spacing={2} direction='row'>
                     <Link
                        sx={{
                           color: theme.palette.grey[600],
                           fontWeight: '500',
                           fontSize: '17px',
                           cursor: 'pointer',
                           '&:hover': {
                              color: theme.palette.primary.light,
                           },
                        }}
                        underline='none'
                        onClick={() => setShowAddList(false)}>
                        Sélectionnez une liste
                     </Link>
                     <Link
                        sx={{
                           color: theme.palette.grey[600],
                           fontWeight: '500',
                           fontSize: '17px',
                           cursor: 'pointer',
                           '&:hover': {
                              color: theme.palette.primary.light,
                           },
                        }}
                        underline='none'
                        onClick={() => setShowAddList(true)}>
                        Créer une liste
                     </Link>
                  </Stack>
               )}
            </Box>
            {confirmList && (
               <Link
                  onClick={() => setconfirmList(false)}
                  sx={{
                     color: theme.palette.primary.light,
                     fontWeight: '500',
                     fontSize: '16px',
                     cursor: 'pointer',
                  }}
                  underline='none'>
                  Modifier
               </Link>
            )}
         </Stack>

         <Divider sx={{ margin: '15px 0', borderWidth: '1px' }} />

         {confirmMapping && !confirmList && (
            <Fragment>
               {/*This contain the table of lists*/}
               {!showAddList && (
                  <Stack sx={{ height: '400px' }} spacing={2}>
                     <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                        <Box
                           sx={{
                              border: `2px ${theme.palette.grey[200]} solid`,
                              display: 'flex',
                              height: 'fit-content',
                              width: { xs: '100%', sm: '60%', md: '300px' },
                           }}>
                           <TextField
                              size='small'
                              sx={{
                                 input: { fontSize: '14px' },
                                 flex: 1,
                                 '& fieldset': {
                                    border: 'none',
                                    borderRadius: '0',
                                 },
                              }}
                              type='text'
                              label='Recherche une liste'
                              variant='outlined'
                           />
                           <IconButton
                              sx={{
                                 borderRadius: '0',
                                 borderLeft: `2px ${theme.palette.grey[200]} solid`,
                              }}>
                              <SearchIcon />
                           </IconButton>
                        </Box>
                        <Button
                           onClick={event => {
                              handleClickPopover(event)
                           }}
                           disableElevation
                           disableTouchRipple
                           variant='contained'
                           size='small'
                           sx={{
                              width: 'fit-content',
                              fontSize: '14px',
                              backgroundColor: '#fff',
                              border: `2px ${theme.palette.grey[200]} solid`,
                              textTransform: 'capitalize',
                              color: theme.palette.grey[800],
                              '&:hover': {
                                 backgroundColor: '#fff',
                              },
                           }}
                           endIcon={<ArrowDropDownIcon />}>
                           Tous les dossiers
                        </Button>
                        {filterFolderPopover}
                     </Stack>

                     <Box sx={{ height: '300px' }}>
                        <DataGrid
                           checkboxSelection={true}
                           rows={lists}
                           columns={dataColumns}
                           disableColumnMenu
                           selectionModel={selecedList}
                           onSelectionModelChange={id => setSelectedList(id)}
                           hideFooter
                        />
                     </Box>

                     <Stack
                        spacing={3}
                        sx={{ marginTop: '15px' }}
                        alignItems='center'
                        justifyContent='center'
                        direction={{ xs: 'column', sm: 'row' }}>
                        <Stack spacing={1}>
                           <Typography>
                              <Typography
                                 component='span'
                                 sx={{
                                    color: theme.palette.primary.light,
                                    marginRight: '5px',
                                    fontWeight: '600',
                                 }}>
                                 {selecedList.length} liste
                              </Typography>
                              sélectionnée
                           </Typography>
                        </Stack>
                        <Button
                           size='small'
                           variant='contained'
                           sx={{
                              backgroundColor: theme.palette.primary.light,
                              width: 'fit-content',
                           }}
                           onClick={() => setconfirmList(true)}>
                           Valider votre liste
                        </Button>
                     </Stack>
                  </Stack>
               )}

               {/*This is create list space */}
               {showAddList && (
                  <Stack sx={{ height: '400px' }} spacing={2}>
                     <TextField
                        variant='outlined'
                        size='small'
                        label='Nom de liste'
                        value={newListName}
                        onChange={event => setNewListName(event.target.value)}
                     />
                     {!openCreateFolder && (
                        <Select
                           value={newListFolder}
                           onChange={event =>
                              setNewListFolder(event.target.value)
                           }
                           size='small'>
                           <MenuItem onClick={() => setOpenCreateFolder(true)}>
                              <Link
                                 underline='none'
                                 sx={{
                                    color: theme.palette.primary.light,
                                    fontSize: '15px',
                                 }}>
                                 Créer un nouveau dossier
                              </Link>
                           </MenuItem>
                           {folders.map((folder, index) => (
                              <MenuItem value={folder.name} key={index}>
                                 {folder.name}
                              </MenuItem>
                           ))}
                        </Select>
                     )}
                     {/*Create New Folder*/}
                     {openCreateFolder && (
                        <Stack
                           spacing={1}
                           direction={{ xs: 'column', sm: 'row' }}>
                           <TextField
                              variant='outlined'
                              size='small'
                              label='Nom de dossier'
                              fullWidth
                              value={newFolderName}
                              onChange={event =>
                                 setNewFolderName(event.target.value)
                              }
                           />
                           <Stack spacing={1} direction='row'>
                              <Button
                                 disableElevation
                                 variant='contained'
                                 onClick={() => {
                                    setFolders([
                                       ...folders,
                                       {
                                          id:
                                             Math.max(
                                                folders.map(item => item.id)
                                             ) + 1,
                                          name: newFolderName,
                                       },
                                    ])
                                    setOpenCreateFolder(false)
                                 }}
                                 sx={{
                                    backgroundColor:
                                       theme.palette.primary.light,
                                    width: 'fit-content',
                                    '&:hover': {
                                       backgroundColor:
                                          theme.palette.primary.light,
                                    },
                                 }}>
                                 Créer
                              </Button>
                              <Button
                                 disableElevation
                                 variant='outlined'
                                 onClick={() => setOpenCreateFolder(false)}>
                                 Annuler
                              </Button>
                           </Stack>
                        </Stack>
                     )}

                     <Button
                        disableElevation
                        variant='contained'
                        sx={{
                           backgroundColor: theme.palette.primary.light,
                           width: 'fit-content',
                           '&:hover': {
                              backgroundColor: theme.palette.primary.light,
                           },
                        }}
                        onClick={() => {
                           addNewListHandler()
                           setNewListName('')
                           setNewListFolder('')
                           setShowAddList(false) //NOTE:
                        }}>
                        Créer
                     </Button>
                  </Stack>
               )}
            </Fragment>
         )}
      </Stack>
   )

   const finalizeImport = (
      <Stack
         spacing={2}
         sx={{
            width: { xs: '100%', sm: '90%', md: '70%' },
         }}>
         <Typography
            variant='h5'
            sx={{ marginBottom: '25px', fontSize: { xs: '20px', sm: '24px' } }}>
            Finalisez votre import
         </Typography>
         <Stack sx={{ marginLeft: '25px !important' }}>
            <Typography
               variant='h6'
               sx={{
                  fontSize: '17px',
                  color: theme.palette.grey[600],
                  fontWeight: '500',
               }}>
               Gestion des contacts
            </Typography>
            <FormGroup sx={{ marginLeft: '10px' }}>
               <FormControlLabel
                  sx={{
                     '& .MuiFormControlLabel-label': { fontSize: '15px' },
                     padding: '7px 0',
                  }}
                  control={
                     <StyledSwitch
                        checked={finalizeSwitch.updateAttr}
                        onChange={handleChange}
                        name='updateAttr'
                     />
                  }
                  label='Mettre à jour les attributs des contacts existants'
               />
               {finalizeSwitch.updateAttr && (
                  <FormControlLabel
                     sx={{
                        '& .MuiFormControlLabel-label': { fontSize: '15px' },
                        padding: '7px 0',
                        marginLeft: '15px',
                     }}
                     control={
                        <StyledSwitch
                           checked={finalizeSwitch.importEmpty}
                           onChange={handleChange}
                           name='importEmpty'
                        />
                     }
                     label='Mettre à jour les attributs des contacts existants'
                  />
               )}
               <FormControlLabel
                  sx={{
                     '& .MuiFormControlLabel-label': { fontSize: '15px' },
                     padding: '7px 0',
                  }}
                  control={
                     <StyledSwitch
                        checked={finalizeSwitch.blockImportedEmails}
                        onChange={handleChange}
                        name='blockImportedEmails'
                     />
                  }
                  label='Bloquer les contacts de messagerie importés'
               />
               <FormControlLabel
                  sx={{
                     '& .MuiFormControlLabel-label': { fontSize: '15px' },
                     padding: '7px 0',
                  }}
                  control={
                     <StyledSwitch
                        checked={finalizeSwitch.blockImportedSms}
                        onChange={handleChange}
                        name='blockImportedSms'
                     />
                  }
                  label='Bloquer les contacts SMS importés'
               />
            </FormGroup>
         </Stack>
         <Stack sx={{ marginLeft: '25px !important' }}>
            <Typography
               variant='h6'
               sx={{
                  fontSize: '17px',
                  color: theme.palette.grey[600],
                  fontWeight: '500',
               }}>
               Accord d'adhésion
            </Typography>
            <Stack sx={{ marginLeft: '10px' }}>
               <FormGroup>
                  <FormControlLabel
                     control={
                        <Checkbox
                           checked={confirm ? true : false}
                           onChange={() => setConfirm(!confirm)}
                           sx={{
                              color: theme.palette.primary.light,
                              '&.Mui-checked': {
                                 color: theme.palette.primary.light,
                              },
                           }}
                        />
                     }
                     label='Je confirme que ma nouvelle importation respecte ces conditions:'
                  />
               </FormGroup>
               <List sx={{ marginLeft: '40px' }} disablePadding>
                  <ListItem
                     disablePadding
                     sx={{ display: 'list-item', listStyleType: 'disc' }}>
                     <ListItemText
                        primaryTypographyProps={{ fontSize: '15px' }}
                        primary="Mes contacts m'ont explicitement autorisé à envoyer des campagnes d'e-mail (newsletter) ou de SMS au cours des deux dernières années ou ont été sollicités au cours des deux dernières années"
                     />
                  </ListItem>
                  <ListItem
                     disablePadding
                     sx={{ display: 'list-item', listStyleType: 'disc' }}>
                     <ListItemText
                        primaryTypographyProps={{ fontSize: '15px' }}
                        primary="Ces contacts n'ont pas été empruntés à un tiers"
                     />
                  </ListItem>
                  <ListItem
                     disablePadding
                     sx={{ display: 'list-item', listStyleType: 'disc' }}>
                     <ListItemText
                        primaryTypographyProps={{ fontSize: '15px' }}
                        primary="ces contacts n'ont pas été achetés ou loués"
                     />
                  </ListItem>
               </List>
               <Typography>
                  Nous pouvons à tout moment suspendre ou annuler toute campagne
                  d'e-mail ou de SMS envoyée aux contacts qui ne respectent pas
                  ces conditions.
               </Typography>
               <Button
                  disableElevation
                  size='small'
                  variant='contained'
                  disabled={!confirm ? true : false}
                  sx={{
                     backgroundColor: theme.palette.primary.light,
                     width: 'fit-content',
                     margin: '15px auto',
                     '&:hover': {
                        backgroundColor: theme.palette.primary.light,
                     },
                  }}>
                  confirmer votre importation
               </Button>
            </Stack>
         </Stack>
      </Stack>
   )

   return (
      <Modal open={open} onClose={handleClose}>
         <Box
            sx={{
               width: '100%',
               height: '100%',
               backgroundColor: '#FFF',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
            }}>
            <Stack
               spacing={2}
               sx={{
                  width: '100%',
                  height: '100%',
                  padding: '20px',
                  overflowY: 'scroll',
               }}>
               <Stack
                  spacing={2}
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'>
                  <Typography variant='h5'>Importation de fichier</Typography>
                  <Button
                     onClick={handleClose}
                     variant='outlined'
                     size='medium'>
                     Annuler
                  </Button>
               </Stack>
               <Divider sx={{ margin: '15px 0' }} />
               <Stack alignItems='center'>
                  {uploadFile}
                  {mappingFile}
                  {selectList}
                  {confirmFile &&
                     confirmMapping &&
                     confirmList &&
                     finalizeImport}
               </Stack>
            </Stack>
         </Box>
      </Modal>
   )
}

export default ModalImport
