import styled from '@emotion/styled'
import {
   Button,
   FormControl,
   IconButton,
   InputAdornment,
   InputBase,
   InputLabel,
   Stack,
   Typography,
} from '@mui/material'
import React, { useState } from 'react'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'

const CustomInput = styled(InputBase)(({ theme }) => ({
   '&': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: '#fcfcfb',
      padding: '3px 12px',
      border: '1px solid #ced4da',
      marginTop: 20,
      width: '100%',
      transition: theme.transitions.create('border-color'),
   },
   '&:focus-within ': {
      borderColor: theme.palette.primary.main,
   },
   '& .MuiInputBase-input': {
      fontSize: 16,
      width: '100%',
   },
}))

const TabPassword = () => {
   const [currPass, setCurrPass] = useState('')
   const [newPass, setnewPass] = useState('')
   const [showCurrPass, setShowCurrPass] = useState(false)
   const [showNewPass, setShowNewPass] = useState(false)
   return (
      <Stack spacing={5}>
         <Stack
            direction={{ xs: 'column', md: 'row' }}
            sx={{ paddingTop: '10px' }}>
            <Typography
               sx={{ width: { xs: '100%', md: '30%' }, marginBottom: '15px' }}
               variant='h5'>
               Password
            </Typography>
            <Stack spacing={2} sx={{ width: { xs: '100%', md: '50%' } }}>
               <FormControl variant='standard' sx={{ width: '100%' }}>
                  <InputLabel
                     shrink
                     htmlFor='currPass-input'
                     sx={{ fontSize: '19px', color: '#000' }}>
                     Current password :
                  </InputLabel>
                  <CustomInput
                     type={showCurrPass ? 'text' : 'password'}
                     id='currPass-input'
                     value={currPass}
                     onChange={e => setCurrPass(e.target.value)}
                     endAdornment={
                        <InputAdornment position='end'>
                           <IconButton
                              aria-label='toggle password visibility'
                              onClick={() => setShowCurrPass(!showCurrPass)}
                              edge='end'>
                              {showCurrPass ? (
                                 <VisibilityOffIcon />
                              ) : (
                                 <VisibilityIcon />
                              )}
                           </IconButton>
                        </InputAdornment>
                     }
                  />
               </FormControl>
               <Stack
                  justifyContent='space-between'
                  direction='row'
                  flexWrap='wrap'>
                  <FormControl variant='standard' sx={{ width: '100%' }}>
                     <InputLabel
                        shrink
                        htmlFor='newPass-input'
                        sx={{ fontSize: '19px', color: '#000' }}>
                        New Password :
                     </InputLabel>
                     <CustomInput
                        type='password'
                        id='newPass-input'
                        value={newPass}
                        onChange={e => setnewPass(e.target.value)}
                        endAdornment={
                           <InputAdornment position='end'>
                              <IconButton
                                 aria-label='toggle password visibility'
                                 onClick={() => setShowCurrPass(!showCurrPass)}
                                 edge='end'>
                                 {showCurrPass ? (
                                    <VisibilityOffIcon />
                                 ) : (
                                    <VisibilityIcon />
                                 )}
                              </IconButton>
                           </InputAdornment>
                        }
                     />
                  </FormControl>
               </Stack>
            </Stack>
         </Stack>
         <Stack direction='row' sx={{ paddingTop: '10px' }}>
            <Typography sx={{ width: '30%' }}></Typography>
            <Button
               disableElevation
               size='small'
               variant='contained'
               sx={{
                  width: 'fit-content',
                  textTransform: 'capitalize',
                  fontSize: '16px',
               }}>
               Update my Password
            </Button>
         </Stack>
      </Stack>
   )
}

export default TabPassword
